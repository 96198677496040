import React, { useEffect, useLayoutEffect } from "react";
import {
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Input,
  DrawerFooter,
  Button,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import { Hooks } from "services";
import { useRedux } from "hooks/redux-hooks";
import InputMask from "components/atoms/InputMask";

interface AddUserProps {
  onClose: () => void;
  projectId: number;
  onSuccess?: () => void;
}

interface Form {
  typeName: string;
}

function AddQuestion(props: AddUserProps) {
  const [addItem, status] = Hooks.AddQuestionType();

  async function onValid(values: Form) {
    addItem(values.typeName);
  }

  useEffect(() => {
    if (status === "fulfilled") {
      props.onSuccess && props.onSuccess();
      props.onClose();
    }
  }, [status]);

  return (
    <DrawerContent>
      <DrawerCloseButton />
      <DrawerHeader>Yeni Soru Tipi Ekle</DrawerHeader>

      <Formik<Form>
        initialValues={{
          typeName: "",
        }}
        onSubmit={onValid}
      >
        <Form>
          <DrawerBody>
            <FormControl mt="3">
              <FormLabel>Soru Tipi</FormLabel>
              <Field as={Input} name="typeName" />
            </FormControl>
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={props.onClose}>
              Vazgeç
            </Button>
            <Button
              colorScheme="blue"
              type="submit"
              isLoading={status === "pending"}
            >
              Oluştur
            </Button>
          </DrawerFooter>
        </Form>
      </Formik>
    </DrawerContent>
  );
}

export default AddQuestion;
