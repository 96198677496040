import { PetApi, StoreApi } from "./pet/api";
import {
  UserApiFactory,
  ControlAreaApiFactory,
  QuestionApiFactory,
  ProjectApiFactory,
  NoticeApiFactory,
  ReportApiFactory,
} from "./swagger/api";
import { useFetchManager, useFetchManagerStore } from "hooks/fetch-manager";
import axios, { AxiosInstance } from "axios";
import { store } from "store";
import {
  UserRoleResponse,
  UserTypeListResponse,
  ProjectShiftListResponse,
  MailListResponse,
  ReportResponse,
  DashboardResponse,
} from "./model";

export type {
  LoginResponse,
  ProjectListResponse,
  ControlAreaListResponse,
  ControlPointListResponse,
  UserTypeListResponse,
  ProjectShiftListResponse,
} from "./model";

const axiosInstance = (): AxiosInstance => {
  const axiosInstance = axios.create();
  // Add a request interceptor
  axiosInstance.interceptors.request.use((config) => {
    return new Promise((resolve) => {
      config.headers.Authorization = "Bearer " + store.getState().auth.token;
      resolve(config);
    });
  });

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log("Interceptors Error", error);

      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

type FactoryFunds = any;
const factoryFunds: FactoryFunds = [
  { isJsonMime: () => true },
  "https://kdsapi.tesisyonetim.pro",
  axiosInstance(),
];

export const Services = {
  User: UserApiFactory(...factoryFunds),
  ControlArea: ControlAreaApiFactory(...factoryFunds),
  Question: QuestionApiFactory(...factoryFunds),
  Project: ProjectApiFactory(...factoryFunds),
  Notice: NoticeApiFactory(...factoryFunds),
  Report: ReportApiFactory(...factoryFunds),
};

export const Hooks = {
  Login: () =>
    useFetchManager<typeof Services.User.apiUserLoginGet>(
      Services.User.apiUserLoginGet
    ),
  ProjectList: () =>
    useFetchManager<typeof Services.User.apiUserGetUserProjectListGet>(
      Services.User.apiUserGetUserProjectListGet
    ),
  ControlAreaList: () =>
    useFetchManager<
      typeof Services.ControlArea.apiControlAreaGetControlAreaListGet
    >(Services.ControlArea.apiControlAreaGetControlAreaListGet),
  ControlPointList: () =>
    useFetchManager<
      typeof Services.ControlArea.apiControlAreaGetControlPointListGet
    >(Services.ControlArea.apiControlAreaGetControlPointListGet),
  QuestionList: () =>
    useFetchManager<typeof Services.Question.apiQuestionGetQuestionListGet>(
      Services.Question.apiQuestionGetQuestionListGet
    ),
  QuestionTypeList: () =>
    useFetchManager<typeof Services.Question.apiQuestionGetQuestionTypeListGet>(
      Services.Question.apiQuestionGetQuestionTypeListGet
    ),
  UserTypeList: () =>
    useFetchManager<
      typeof Services.User.apiUserGetUserTypeListGet,
      UserTypeListResponse
    >(Services.User.apiUserGetUserTypeListGet),
  AddQuestion: () =>
    useFetchManager<typeof Services.Question.apiQuestionAddQuestionPost>(
      Services.Question.apiQuestionAddQuestionPost
    ),
  QuestionPeriodList: () =>
    useFetchManager<
      typeof Services.Question.apiQuestionGetQuestionPeriodListGet
    >(Services.Question.apiQuestionGetQuestionPeriodListGet),
  ProjectShiftList: () =>
    useFetchManager<
      typeof Services.Project.apiProjectGetProjectShiftListGet,
      ProjectShiftListResponse
    >(Services.Project.apiProjectGetProjectShiftListGet),
  AddControlArea: () =>
    useFetchManager<
      typeof Services.ControlArea.apiControlAreaAddControlAreaGet
    >(Services.ControlArea.apiControlAreaAddControlAreaGet),
  AddControlPoint: () =>
    useFetchManager<
      typeof Services.ControlArea.apiControlAreaAddControlPointPost
    >(Services.ControlArea.apiControlAreaAddControlPointPost),
  UserList: () =>
    useFetchManager<typeof Services.User.apiUserGetProjectInUserGet>(
      Services.User.apiUserGetProjectInUserGet
    ),
  UserRoleList: () =>
    useFetchManager<
      typeof Services.User.apiUserGetUserRoleListGet,
      UserRoleResponse
    >(Services.User.apiUserGetUserRoleListGet),
  AddUser: () =>
    useFetchManager<typeof Services.User.apiUserAddUserPost>(
      Services.User.apiUserAddUserPost
    ),
  EditUser: () =>
    useFetchManager<typeof Services.User.apiUserUpdateUserPost>(
      Services.User.apiUserUpdateUserPost
    ),
  AddShift: () =>
    useFetchManager<typeof Services.Project.apiProjectAddProjectShiftPost>(
      Services.Project.apiProjectAddProjectShiftPost
    ),
  MailList: () =>
    useFetchManager<
      typeof Services.Project.apiProjectGetProjectMailListGet,
      MailListResponse
    >(Services.Project.apiProjectGetProjectMailListGet),
  AddMail: () =>
    useFetchManager<typeof Services.Project.apiProjectAddProjectMailGet>(
      Services.Project.apiProjectAddProjectMailGet
    ),
  UpdateShift: () =>
    useFetchManager<typeof Services.Project.apiProjectUpdateProjectShiftPost>(
      Services.Project.apiProjectUpdateProjectShiftPost
    ),
  UpdateUser: () =>
    useFetchManager<typeof Services.User.apiUserUpdateUserPost>(
      Services.User.apiUserUpdateUserPost
    ),
  UpdateMail: () =>
    useFetchManager<typeof Services.Project.apiProjectUpdateProjectMailPost>(
      Services.Project.apiProjectUpdateProjectMailPost
    ),
  UserActive: () =>
    useFetchManager<typeof Services.User.apiUserUserActiveGet>(
      Services.User.apiUserUserActiveGet
    ),
  UserPassive: () =>
    useFetchManager<typeof Services.User.apiUserUserPassiveGet>(
      Services.User.apiUserUserPassiveGet
    ),
  AddQuestionType: () =>
    useFetchManager<typeof Services.Question.apiQuestionAddQuestionTypeGet>(
      Services.Question.apiQuestionAddQuestionTypeGet
    ),
  ListQuetionType: () =>
    useFetchManager<typeof Services.Question.apiQuestionGetQuestionTypeListGet>(
      Services.Question.apiQuestionGetQuestionTypeListGet
    ),
  NoticeList: () =>
    useFetchManager<typeof Services.Notice.apiNoticeGetAllNoticeGet>(
      Services.Notice.apiNoticeGetAllNoticeGet
    ),
  UpdateNotice: () =>
    useFetchManager<typeof Services.Notice.apiNoticeUpdateNoticePost>(
      Services.Notice.apiNoticeUpdateNoticePost
    ),
  AddNotice: () =>
    useFetchManager<typeof Services.Notice.apiNoticeAddNoticePost>(
      Services.Notice.apiNoticeAddNoticePost
    ),
  ChangePassword: () =>
    useFetchManager<typeof Services.User.apiUserChanceUserPasswordGet>(
      Services.User.apiUserChanceUserPasswordGet
    ),
  Report: () =>
    useFetchManager<
      typeof Services.Report.apiReportGetDailyReportByDateGet,
      ReportResponse
    >(Services.Report.apiReportGetDailyReportByDateGet),
  Dashboard: () =>
    useFetchManager<
      typeof Services.Report.apiReportGetControlPanelGet,
      DashboardResponse
    >(Services.Report.apiReportGetControlPanelGet),
  AllList: () =>
    useFetchManager<typeof Services.Question.apiQuestionGetAllQuestionListGet>(
      Services.Question.apiQuestionGetAllQuestionListGet
    ),
  UpdateControlArea: () =>
    useFetchManager<
      typeof Services.ControlArea.apiControlAreaUpdateControlAreaPost
    >(Services.ControlArea.apiControlAreaUpdateControlAreaPost),
  UpdateControlPoint: () =>
    useFetchManager<
      typeof Services.ControlArea.apiControlAreaUpdateControlPointPost
    >(Services.ControlArea.apiControlAreaUpdateControlPointPost),
  UpdateQuestion: () =>
    useFetchManager<typeof Services.Question.apiQuestionUpdateQuestionPost>(
      Services.Question.apiQuestionUpdateQuestionPost
    ),
  AllUsers: () =>
    useFetchManager<typeof Services.User.apiUserGetUserListGet>(
      Services.User.apiUserGetUserListGet
    ),
  AllProjects: () =>
    useFetchManager<typeof Services.Project.apiProjectGetProjectListGet>(
      Services.Project.apiProjectGetProjectListGet
    ),
  AddUserToProject: () =>
    useFetchManager<typeof Services.User.apiUserAddUserInProjectGet>(
      Services.User.apiUserAddUserInProjectGet
    ),
};
