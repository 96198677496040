import React from "react";
import { Badge } from "@chakra-ui/react";

function StatusBadge({ stat }: { stat: boolean }) {
  return (
    <Badge colorScheme={stat ? "green" : "gray"}>
      {stat ? "Aktif" : "Pasif"}
    </Badge>
  );
}

export default StatusBadge;
