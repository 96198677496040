import React, { PropsWithChildren } from "react";
import { Box, BoxProps, useColorMode, styled } from "@chakra-ui/react";

const Card = styled(Box, {
  baseStyle: {
    background: "white",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    padding: "5",
    borderRadius: "md"
  },
});

export default Card;
