import React from "react";
import {
  Box,
  Flex,
  Image,
  Icon,
  useMediaQuery,
  Button,
} from "@chakra-ui/react";
import BasicTile from "components/molecules/BasicTile";
import { StarIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
import {
  FaBoxes,
  FaHome,
  FaUsers,
  FaClock,
  FaEnvelope,
  FaQuestion,
  FaBell,
  FaEdit,
  FaArchive,
  FaBars,
  FaTimes,
} from "react-icons/fa";
import { mainPaths } from "router/path";
import { useRedux } from "hooks/redux-hooks";

function Sidebar(props: any) {
  const history = useHistory();
  const [{ auth }] = useRedux();
  const [isMobile] = useMediaQuery("(max-width: 650px)");

  return (
    <Box overflow="hidden" minH="400px" minW="250px" bgColor="blue.500">
      <Box padding="5" paddingY="0" backgroundColor="white" mb="2">
        {isMobile && (
          <Flex justifyContent="space-between" alignItems="center">
            <Flex height="72px" alignItems="center">
              <Image
                src={auth.user.companyLogo}
                alt="Borsa İstanbul"
                w="200px"
                h={"50px"}
              />
            </Flex>
            <Button
              colorScheme="blue"
              variant="outline"
              size="sm"
              onClick={props.sidebarToggle}
            >
              <Icon as={FaTimes} />
            </Button>
          </Flex>
        )}
        {!isMobile && (
          <Flex height="72px" alignItems="center">
            <Image
              src={auth.user.companyLogo}
              alt="Borsa İstanbul"
              w="200px"
              h={"50px"}
            />
          </Flex>
        )}
      </Box>

      <Box p="5">
        <BasicTile
          icon={<Icon as={FaHome} />}
          title="Kontrol Merkezi"
          path="/"
        />
        <BasicTile
          icon={<Icon as={FaBoxes} />}
          title="Kontrol Bölgeleri"
          path={mainPaths.ControlArea("a")}
        />
        <BasicTile
          icon={<Icon as={FaEdit} />}
          title="Kontrol Sonuçları"
          path={mainPaths.Report}
        />
        <BasicTile
          icon={<Icon as={FaArchive} />}
          title="Kontrol Listeleri"
          path={mainPaths.List}
        />
        <BasicTile
          icon={<Icon as={FaUsers} />}
          title="Kullanıcılar"
          path="/user"
        />
        <BasicTile
          icon={<Icon as={FaClock} />}
          title="Kontrol Saatleri"
          path="/shift"
        />
        <BasicTile
          icon={<Icon as={FaEnvelope} />}
          title="Bildirim Adresleri"
          path="/mail"
        />
        <BasicTile
          icon={<Icon as={FaQuestion} />}
          title="Soru Tipleri"
          path={mainPaths.QuestionType}
        />
        <BasicTile
          icon={<Icon as={FaBell} />}
          title="Duyurular"
          path={mainPaths.Notice}
        />
        {auth.user.username.includes("vk") &&
          auth.user.userRoleName === "Admin" && (
            <BasicTile
              icon={<Icon as={FaUsers} />}
              title="Kullanıcı Projeleri"
              path={mainPaths.UsersAndProjects}
            />
          )}
      </Box>
    </Box>
  );
}

export default Sidebar;
