import React from "react";
import {
  Flex,
  Box,
  Text,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";

interface SureProps {
  onClose?: any;
  isOpen: any;
  onSuccess: any;
}

function Sure({ isOpen, onClose, onSuccess }: SureProps) {
  return (
    <AlertDialog
      leastDestructiveRef={undefined}
      isOpen={isOpen}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Emin Misiniz?
          </AlertDialogHeader>

          <AlertDialogBody>
            Bu isleme devam etmek istediginizden emin misiniz?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onClose}>Vazgec</Button>
            <Button
              colorScheme="blue"
              autoFocus
              onClick={(e) => {
                onSuccess();
                onClose();
              }}
              ml={3}
            >
              Devam Et
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default Sure;
