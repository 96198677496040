import MainTemplate from "components/templates/Main";
import React, { useLayoutEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  Button,
  Text,
  Flex,
  Divider,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  IconButton,
} from "@chakra-ui/react";
import Card from "../atoms/Card";
import { Hooks, ControlPointListResponse } from "services";
import { useRedux } from "hooks/redux-hooks";
import { RouteComponentProps } from "react-router-dom";
import { mainPaths } from "router/path";
import { AddIcon, RepeatIcon } from "@chakra-ui/icons";
import AddControlPoint from "components/organizms/AddControlPoint";
import StatusBadge from "components/atoms/StatusBadge";
import Moment from "moment";
import DataTable from "components/atoms/DataTable";
import SectionTitle from "components/molecules/SectionTitle";
import Sure from "components/molecules/Sure";

type Params = {
  projectId: string;
  controlAreaId: string;
};

function ControlPoint({ match, history }: RouteComponentProps<Params>) {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const [getProjectList, status, data] = Hooks.ProjectList();
  const [getControlAreaList, cAStat, cAData] = Hooks.ControlAreaList();
  const [getControlPointList, cPStat, cPData] = Hooks.ControlPointList();
  const [updateCP, statusCP, dataCP] = Hooks.UpdateControlPoint();
  const [{ auth }] = useRedux();
  const [stt, setStt] = useState<any>()

  async function willMount() {
    // await getProjectList(auth.user.id);
    getControlPointList(parseInt(match.params.controlAreaId));
  }

  function goQuestions(controlPointId: number) {
    history.push(
      mainPaths.Question(
        auth.user.project.id,
        match.params.controlAreaId,
        controlPointId
      )
    );
  }

  async function _updateControlPoint(_stt: any) {
    await updateCP({ ..._stt, isActive: !_stt.isActive });
    getControlPointList(parseInt(match.params.controlAreaId));
  }

  useLayoutEffect(() => {
    willMount();
  }, []);

  const controlPointList = (cPData?.data as unknown) as ControlPointListResponse;

  return (
    <MainTemplate>
    <SectionTitle
      title="Kontrol Noktaları"
      button={{
        colorScheme: "blue",
        onClick: onToggle,
        title: "Yeni Kontrol Noktası Ekle",
      }}
    />
    <Card>
      {cPStat !== "fulfilled" ? (
        <Flex justifyContent="center" alignItems="center">
          <Spinner />
        </Flex>
      ) : (
        <DataTable
          columns={[
            {
              name: "Id",
              selector: "id",
              sortable: true,
            },
            {
              name: "Kontrol Bölgesi",
              selector: "controlAreaName",
              width: "250px",
              sortable: true,
            },
            {
              name: "Kontrol Noktası Adı",
              selector: "name",
              width: "300px",
              sortable: true,
            },
            {
              name: "Tanım Tarihi",
              cell: (row: any) => {
                return Moment(row.createDate).format("DD.MM.YYYY");
              },
              sortable: true,
              selector: "createDate"
            },
            {
              name: "Durum",
              selector: "isActive",
              cell: (row: any) => {
                return <StatusBadge stat={row.isActive} />;
              },
            },
            {
              name: "Sorular",
              cell: (controlPoint: any) => {
                return (
                  <Button
                  colorScheme="blue"
                  size="sm"
                  onClick={() => {
                    goQuestions(controlPoint.id);
                  }}
                >
                  <Text fontSize="xs">Sorular</Text>
                </Button>
                );
              },
              width: "200px"
            },
            {
              name: "Aktif / Pasif",
              cell: (row: any, index: number) => {
                if (auth.user.userRoleID === 3) return null;
                return (
                  <IconButton
                    size="sm"
                    aria-label="ChangeStatus"
                    icon={<RepeatIcon />}
                    onClick={(e) => {
                      setStt(row);
                    }}
                  />
                );
              },
            },
          ]}
          data={cPData?.data.data.map((item: any) => ({
            ...item,
          }))}
          excel={[{ label: "Deneme", value: "Deneme" }]}
        />
      )}
    </Card>
    <Sure
        isOpen={stt !== undefined}
        onClose={() => setStt(undefined)}
        onSuccess={() => stt !== undefined && _updateControlPoint(stt)}
      />
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay>
          <AddControlPoint
            {...{
              onClose,
              controlAreaId: parseInt(match.params.controlAreaId),
              projectId: parseInt(auth.user.project.id),
              onSuccess: () => {
                getControlPointList(parseInt(match.params.controlAreaId));
              },
            }}
          />
        </DrawerOverlay>
      </Drawer>
  </MainTemplate>
  )

  return (
    <MainTemplate>
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontWeight="bold" fontSize="xl">
          Kontrol Noktaları
        </Text>
        <Button
          leftIcon={<AddIcon />}
          colorScheme="blue"
          size="sm"
          onClick={onOpen}
        >
          Yeni Kontrol Noktası Ekle
        </Button>
      </Flex>
      <Divider mb="3" mt="2" />
      <Card>
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th padding="4">ID</Th>
              <Th>Kontrol Bölgesi</Th>
              <Th>Kontrol Noktası</Th>
              <Th>Tanım Tarihi</Th>
              <Th>Tanımlayan</Th>
              <Th>Durum</Th>
              <Th>Durum Değiştir</Th>
              <Th>Sorular</Th>
            </Tr>
          </Thead>
          <Tbody>
            {cPStat !== "fulfilled" && (
              <Tr>
                <Td colSpan={3} textAlign="center">
                  <Spinner />
                </Td>
              </Tr>
            )}
            {cPStat === "fulfilled" &&
              controlPointList.data.map((controlPoint, index) => (
                <Tr key={index}>
                  <Td>{controlPoint.id}</Td>
                  <Td>{controlPoint.controlAreaID}</Td>
                  <Td>{controlPoint.name}</Td>
                  <Td>
                    {Moment(controlPoint.createDate).format("DD.MM.YYYY")}
                  </Td>
                  <Td>Tanımlayan Kişi</Td>
                  <Td>
                    <StatusBadge stat={controlPoint.isActive} />
                  </Td>
                  <Td>
                    <Button size="sm" colorScheme="purple">
                      <Text fontSize="xs">Değiştir</Text>
                    </Button>
                  </Td>

                  <Td>
                    <Button
                      colorScheme="blue"
                      size="sm"
                      onClick={() => {
                        goQuestions(controlPoint.id);
                      }}
                    >
                      <Text fontSize="xs">Sorular</Text>
                    </Button>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Card>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay>
          <AddControlPoint
            {...{
              onClose,
              controlAreaId: parseInt(match.params.controlAreaId),
              projectId: parseInt(auth.user.project.id),
              onSuccess: () => {
                getControlPointList(parseInt(match.params.controlAreaId));
              },
            }}
          />
        </DrawerOverlay>
      </Drawer>
    </MainTemplate>
  );
}

export default ControlPoint;
