import React from "react";
import {
  Flex,
  Box,
  Text,
  BoxProps,
  Icon,
  Select,
  Progress,
  ColorProps,
  Skeleton,
} from "@chakra-ui/react";
import Card from "components/atoms/Card";
import { rest } from "lodash";
import { title } from "process";
import { FaAirbnb } from "react-icons/fa";
import { IconType } from "react-icons";

interface InfoCardProps {
  cardColor?: any;
  value?: number;
  description?: string;
  title?: string;
  icon?: IconType;
  isLoading?: boolean;
  total?: number;
}

function InfoCard({
  cardColor,
  value,
  description,
  title,
  icon,
  isLoading,
  total,
  ...rest
}: InfoCardProps & BoxProps) {
  return (
      <Card h="100%" display="flex" flexDir="column">
        <Flex justifyContent="center" flexDir="column">
          <Icon as={icon} fontSize="xl" color={"gray.600"} />
          <Text
            fontSize="sm"
            color={"gray.600"}
            fontWeight="bold"
            textTransform="uppercase"
          >
            {title}
          </Text>
        </Flex>
        <Flex
          mt="6"
          alignItems="stretch"
          flexDir="column"
          justifyContent="center"
        >
          <Flex
            textAlign="center"
            
            fontSize="3xl"
            fontWeight="bold"
            alignItems="center"
            justifyContent="center"
          >
            <Text color={`${cardColor}.500`}>{value}</Text>
            {total !== undefined && (
              <Text fontSize="2xl" color="gray.500">
                &nbsp;/ {`${total}`}&nbsp;
              </Text>
            )}
            {total !== undefined && (
              <Text fontSize="2xl" color="red.300">
                {" "}
                / {total - value!}
              </Text>
            )}
          </Flex>
          {total !== undefined && (
            <Flex justifyContent="center" mb="3">
              <Text fontSize="10px">Yanıtlanan/Toplam/Yanitlanmayan</Text>
            </Flex>
          )}
          {total !== undefined ? (
            total !== 0 && (
              <Progress
                value={value}
                max={total}
                rounded="sm"
                mb="4"
                colorScheme={"blue"}
              />
            )
          ) : (
            <Box textAlign="center" color="red.500" fontWeight="semibold">
              <Text fontSize="xl">Adet</Text>
            </Box>
          )}
        </Flex>
      </Card>
  );
}

export default InfoCard;
