import React, { FC, Fragment, memo, ReactNode, useRef, useState } from "react";
import { Box, Flex, Heading, Input, Text } from "@chakra-ui/react";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

type FlatListProps = {
  data: any[];
  renderItem: (args: { item: any; index: number }) => ReactNode;
  title?: string;
  searchFields?: string[];
};

const FlatList: FC<FlatListProps> = (props) => {
  const listRef = useRef<FixedSizeList>(null);
  const [state, setState] = useState({
    query: "",
  });

  let data = props.data;
  if (props.searchFields && props.searchFields.length > 0) {
    data = data.filter((item) => {
      let hasSearched = false;
      let searchValue = "";
      if (state.query === "") hasSearched = true;
      props.searchFields?.forEach((searchField) => {
        searchValue += " " + item[searchField];
      });
      if (
        searchValue.toLowerCase().includes(state.query.toLowerCase()) ||
        state.query.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        hasSearched = true;
      }
      return hasSearched;
    });
  }

  const Row = memo(({ index, style }: any) => {
    const item = data[index];
    return <div style={style}>{props.renderItem({ item, index })}</div>;
  });

  return (
    <Box h="full">
      {!!props.title && (
        <Heading mb="2" size="sm">
          {props.title}
        </Heading>
      )}
      {props.searchFields && props.searchFields.length > 0 && (
        <Input
          placeholder="Ara..."
          value={state.query}
          onChange={(e) =>
            setState((prev) => ({ ...prev, query: e.target.value }))
          }
        />
      )}
      <AutoSizer>
        {({ height, width }) => (
          <FixedSizeList
            ref={listRef}
            height={height}
            itemCount={data.length}
            itemSize={80}
            width={width}
          >
            {Row}
          </FixedSizeList>
        )}
      </AutoSizer>
    </Box>
  );
};

export default FlatList;
