import { Box } from "@chakra-ui/react";
import React, { useLayoutEffect } from "react";
import Dashboard from "components/pages/Dashboard";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Router, { AuthRouter } from "router";
import { useRedux } from "hooks/redux-hooks";
import { getUserAndToken } from "utils/storage-manager";
import { Authenticator } from "utils/authenticator";

function App() {
  const [{ auth }] = useRedux();
  useLayoutEffect(() => {
    const very = getUserAndToken();
    if (very) {
      if (!!very.user.project) {
        Authenticator.Login(very.token, very.user);
        Authenticator.SetProject(very.token, very.user, very.user.project);
      } else {
        Authenticator.Login(very.token, very.user);
      }
    }
  }, []);
  return (
    <Box>
      <BrowserRouter>
        <Switch>
          {auth.status === "notHaveProject" ? (
            <Switch>
              <Route
                path=""
                component={require("components/pages/SelectProject").default}
              />
            </Switch>
          ) : auth.status === "logged" ? (
            <Router />
          ) : (
            <AuthRouter />
          )}
        </Switch>
      </BrowserRouter>
    </Box>
  );
}

export default App;
