import { extendTheme } from "@chakra-ui/react";

export const colors = {
  black: "#4A4A4A",
  background: "#F7F7F7",
  purple: {
    50: "#dadef6",
    100: "#c7cdf1",
    200: "#a2ace8",
    300: "#7c8bde",
    400: "#576ad5",
    500: "#4459d0",
    600: "#3d50bb",
    700: "#303e92",
    800: "#222d68",
    900: "#141b3e",
  },
  red: {
    50: "#fde4e0",
    100: "#fcd7d1",
    200: "#fbbcb2",
    300: "#f9a193",
    400: "#f78674",
    500: "#f67964",
    600: "#dd6d5a",
    700: "#ac5546",
    800: "#7b3d32",
    900: "#4a241e",
  },
  gray: {
    50: "#f7f7f7",
    100: "#dedede",
    200: "#c6c6c6",
    300: "#adadad",
    400: "#949494",
    500: "#7c7c7c",
    600: "#636363",
    700: "#4a4a4a",
    800: "#313131",
    900: "#191919",
  },
  green: {
    50: "#d3eeeb",
    100: "#c4e8e5",
    200: "#b5e2de",
    300: "#97d6d1",
    400: "#7acbc4",
    500: "#6bc5bd",
    600: "#60b1aa",
    700: "#4b8a84",
    800: "#36635f",
    900: "#203b39",
  },
  yellow: {
    50: "#fdfadf",
    100: "#fcf7cf",
    200: "#faf2af",
    300: "#f8ed8e",
    400: "#f6e86e",
    500: "#f5e55e",
    600: "#ddce55",
    700: "#aca042",
    800: "#7b732f",
    900: "#49451c",
  },
};

export const theme = extendTheme({
  colors: colors,
});
