import React, { useState, useRef, useEffect } from "react";
import {
  Flex,
  Box,
  Text,
  Input,
  FormControl,
  FormLabel,
  Button,
  Heading,
  Divider,
  useToast,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import Card from "components/atoms/Card";
import { Formik, Form, Field, FormikProps } from "formik";
import { useRedux } from "hooks/redux-hooks";
import { Hooks } from "services";

interface Form {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

function Settings() {
  const [{ auth }] = useRedux();
  const [changePass, statusPass, dataPass] = Hooks.ChangePassword();
  const toast = useToast();
  const [isShow, setShow] = useState<boolean>(false);
  const formRef = useRef<FormikProps<Form>>(null);

  function _handleSubmit(values: Form) {
    if (values.newPassword === values.newPasswordConfirm) {
      changePass(auth.user.id, values.oldPassword, values.newPassword);
    } else {
      toast({
        title: "Hata oluştu!",
        description: "Şifreler Eşleşmiyor.",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
      formRef.current?.setFieldValue("newPasswordConfirm", "");
    }
  }

  useEffect(() => {
    if (statusPass === "fulfilled") {
      formRef.current?.resetForm();
      const _data: any = dataPass;
      if (_data.data.success) {
        toast({
          title: "İşlem Başarılı!",
          description: "Şifreniz başarıyla değiştirildi.",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      } else {
        toast({
          title: "Hata oluştu!",
          description: "Eski şifreniz yanlış olabilir.",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      }
    } else if (statusPass === "rejected") {
      toast({
        title: "Hata oluştu!",
        description: "Eski şifreniz yanlış olabilir.",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [statusPass]);

  return (
    <Main>
      <Card>
        <Heading fontSize="xl">Şifre Değiştir</Heading>
        <Divider mt="5" />
        <Formik<Form>
          initialValues={{
            newPassword: "",
            oldPassword: "",
            newPasswordConfirm: "",
          }}
          onSubmit={_handleSubmit}
          innerRef={formRef}
        >
          <Form>
            <FormControl mt="3">
              <FormLabel>Mevcut Şifre</FormLabel>
              <Field
                as={Input}
                required
                minLength="4"
                name="oldPassword"
                type={isShow ? "text" : "password"}
              />
            </FormControl>
            <FormControl mt="3">
              <FormLabel>Yeni Şifre</FormLabel>
              <Field
                as={Input}
                required
                minLength="4"
                name="newPassword"
                type={isShow ? "text" : "password"}
              />
            </FormControl>
            <FormControl mt="3">
              <FormLabel>Yeni Şifre Tekrar</FormLabel>
              <Field
                as={Input}
                required
                minLength="4"
                name="newPasswordConfirm"
                type={isShow ? "text" : "password"}
              />
            </FormControl>
            <Box textAlign="right" mt="3">
              <Button
                mr="2"
                variant={isShow ? "solid" : "ghost"}
                onClick={(e) => setShow((_item) => !_item)}
              >
                Şifreleri {isShow ? "Gizle" : "Göster"}
              </Button>
              <Button
                type="submit"
                colorScheme="blue"
                isLoading={statusPass === "pending"}
              >
                Değiştir
              </Button>
            </Box>
          </Form>
        </Formik>
      </Card>
    </Main>
  );
}

export default Settings;
