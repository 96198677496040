import React from "react";
import { Flex, Box, Text } from "@chakra-ui/react";
import MainCrud from "components/templates/_Main-Crud";
import { Hooks } from "services";
import AddQuestionType from "components/organizms/AddQuestionType";

interface ValuesProps {}

function Values({}: ValuesProps) {
  return (
    <MainCrud
      listService={{ hook: Hooks.ListQuetionType }}
      title="Değer Tanımlama"
      newButtonText="Yeni Değer Tanımlama"
      updateService={{ hook: () => [null, null] }}
      addForm={{ Form: AddQuestionType, props: [] }}
      columns={[
        {
          name: "Soru Tipi",
          selector: "name",
          sortable: true,
        },
      ]}
    />
  );
}

export default Values;
