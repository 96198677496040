import React, { useLayoutEffect, useEffect }  from "react";
import MainTemplate from "components/templates/Main";
import {
  Heading,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Button,
  DrawerBody,
  DrawerFooter,
  Select,
  Flex,
  useToast,
} from "@chakra-ui/react";
import Card from "components/atoms/Card";
import { useCurrentUser, useRedux } from "hooks/redux-hooks";
import { Formik, Form, Field, FormikProps } from "formik";
import { Hooks } from "services";

function InfoEdit() {
  const [getRoles, statRoles, dataRoles] = Hooks.UserRoleList();
  const [getTypes, statTypes, dataTypes] = Hooks.UserTypeList();
  const updateUser = Hooks.UpdateUser();
  const [{ auth }] = useRedux();
  const toast = useToast();

  async function onValid(values: any) {
    updateUser[0]({
      ...auth.user,
      ...values,
      userRoleID: parseInt(values.userRoleID),
      userTypeID: parseInt(values.userTypeID),
    });
  }

  useLayoutEffect(() => {
    getRoles();
    getTypes();
  }, []);

  useEffect(() => {
    if (updateUser[1] === "fulfilled") {
      toast({title: "Başarıyla güncellendi!", status: "success"})
    } else if(updateUser[1] === "rejected") {
      toast({title: "Hata oluştu!", status: "error"})
    }
  }, [updateUser[1]]);

  return (
    <MainTemplate>
      <Card>
        <Heading fontSize="xl">Bilgilerimi Güncelle</Heading>
        <Divider mt="5" />
        <Formik
          initialValues={{
            name: auth.user.name || "",
            surname: auth.user.surname || "",
            userRoleID: auth.user.userRoleID || "",
            userTypeID: auth.user.userTypeID || "",
          }}
          onSubmit={onValid}
        >
          <Form>
              <FormControl mt="3">
                <FormLabel>Ad</FormLabel>
                <Field as={Input} name="name" />
              </FormControl>
              <FormControl mt="3">
                <FormLabel>Soyad</FormLabel>
                <Field as={Input} name="surname" />
              </FormControl>
              <FormControl mt="3">
                <FormLabel>Kullanıcı Adı</FormLabel>
                <Input disabled value={auth.user.username} />
              </FormControl>
              <FormControl mt="3">
                <FormLabel>Kullanıcı Rolü</FormLabel>
                <Field
                  as={Select}
                  name="userRoleID"
                  disabled={true}
                >
                  {statRoles === "fulfilled" &&
                    dataRoles?.data.data.map((item, index) => (
                      <option key={"role" + index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Field>
              </FormControl>
              <FormControl mt="3">
                <FormLabel>Kullanıcı Tipi</FormLabel>
                <Field
                  as={Select}
                  name="userTypeID"
                  disabled={true}
                >
                  {statRoles === "fulfilled" &&
                    dataTypes?.data.data.map((item, index) => (
                      <option key={"type" + index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Field>
              </FormControl>
            <Flex mt="3" justifyContent="flex-end">
              <Button
                colorScheme="purple"
                type="submit"
                isLoading={updateUser[1] === "pending"}
              >
                Düzenle
              </Button>
            </Flex>
          </Form>
        </Formik>
      </Card>
    </MainTemplate>
  );
}

export default InfoEdit;
