import MainTemplate from "components/templates/Main";
import React, { useLayoutEffect, useState } from "react";
import {
  Flex,
  Box,
  Text,
  Spinner,
  Drawer,
  DrawerOverlay,
  useDisclosure,
  Button,
  IconButton,
} from "@chakra-ui/react";
import { RepeatIcon } from "@chakra-ui/icons";
import SectionTitle from "components/molecules/SectionTitle";
import { Hooks } from "services";
import { useRedux } from "hooks/redux-hooks";
import Card from "components/atoms/Card";
import DataTable from "components/atoms/DataTable";
import Moment from "moment";
import StatusBadge from "components/atoms/StatusBadge";
import AddUser from "components/organizms/AddUser";
import AddShift from "components/organizms/AddShift";
import Sure from "components/molecules/Sure";

function Shift() {
  const [getList, statusList, dataList] = Hooks.ProjectShiftList();
  const [updateShift, statusUpShift] = Hooks.UpdateShift();
  const [{ auth }] = useRedux();
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const [stt, setStt] = useState<number>();

  function _getList() {
    getList(auth.user.project.id);
  }

  async function _toggleShift(index: number) {
    const _shift = dataList?.data.data[index];
    if (!!_shift) {
      await updateShift({ ..._shift, isActive: !_shift.isActive });
      _getList();
    }
  }

  useLayoutEffect(() => {
    _getList();
  }, []);

  return (
    <MainTemplate>
      <SectionTitle
        title="Kontrol Saatleri"
        button={{
          colorScheme: "blue",
          onClick: onToggle,
          title: "Yeni Kontrol Saati Oluştur",
        }}
      />
      <Card>
        {statusList !== "fulfilled" ? (
          <Flex justifyContent="center" alignItems="center">
            <Spinner />
          </Flex>
        ) : (
          <DataTable
            columns={[
              {
                name: "Id",
                selector: "id",
                sortable: true,
              },
              {
                name: "Başlangıç Saati",
                selector: "startHour",
                sortable: true,
              },
              {
                name: "Bitiş Saati",
                selector: "endHour",
                sortable: true,
              },
              {
                name: "Tanım Tarihi",
                selector: "createDate",
                sortable: true,
                cell: (row: any) => {
                  return Moment(row.createDate).format("DD.MM.YYYY");
                },
              },
              {
                name: "Durum",
                selector: "isActive",
                sortable: true,
                cell: (row: any) => {
                  return <StatusBadge stat={row.isActive} />;
                },
              },
              {
                name: "Aktif / Pasif",
                cell: (row: any, index: number) => {
                  if (auth.user.userRoleID === 3) return null;
                  return (
                    <IconButton
                      size="sm"
                      aria-label="ChangeStatus"
                      icon={<RepeatIcon />}
                      onClick={(e) => {
                        setStt(index);
                      }}
                    />
                  );
                },
              },
            ]}
            data={dataList?.data?.data && dataList.data.data}
            excel={[{ label: "Deneme", value: "Deneme" }]}
          />
        )}
      </Card>
      <Sure
        isOpen={stt !== undefined}
        onClose={() => setStt(undefined)}
        onSuccess={() => stt !== undefined && _toggleShift(stt)}
      />
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay>
          <AddShift
            {...{
              onClose,
              projectId: parseInt(auth.user.project.id),
              onSuccess: _getList,
            }}
          />
        </DrawerOverlay>
      </Drawer>
    </MainTemplate>
  );
}

export default Shift;
