import React, { Fragment, useEffect, useLayoutEffect } from "react";
import {
  Button,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Input,
  Textarea,
  FormControl,
  FormLabel,
  FormHelperText,
  Select,
  useToast,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import {
  Hooks,
  UserTypeListResponse,
  ProjectShiftListResponse,
} from "services";
import { useRedux } from "hooks/redux-hooks";

interface AddQuestionProps {
  onClose: () => void;
  controlAreaId: number;
  controlPointId: number;
  projectId: number;
  onSuccess?: () => void;
}

interface Form {
  questionText: string;
  userTypeID: string;
  questionTypeID: string;
  questionPeriodID: string;
  initialValue: string;
  endValue: string;
  projectShiftID: string;
  periodicDay: string;
}

export const DAYS = [
  "Pazartesi",
  "Sali",
  "Çarsamba",
  "Persembe",
  "Cuma",
  "Cumartesi",
  "Pazar",
];

function AddQuestion(props: AddQuestionProps) {
  const [getTypes, statusTypes, dataTypes] = Hooks.QuestionTypeList();
  const [getUserTypes, statusUserTypes, dataUserTypes] = Hooks.UserTypeList();
  const [getPeriods, statusPeriods, dataPeriods] = Hooks.QuestionPeriodList();
  const [getShift, statusShift, dataShifts] = Hooks.ProjectShiftList();
  const [postAddQuestion, statusAddQuest, dataAddQuest] = Hooks.AddQuestion();
  const [{ auth }] = useRedux();
  const toast = useToast();

  function onValid(values: Form) {
    postAddQuestion({
      endValue: values.endValue.toString(),
      initialValue: values.initialValue.toString(),
      questionText: values.questionText,
      questionTypeID: parseInt(values.questionTypeID),
      userTypeID: parseInt(values.userTypeID),
      controlAreaID: props.controlAreaId,
      projectID: auth.user.project.id,
      controlPointID: props.controlPointId,
      questionPeriodID: parseInt(values.questionPeriodID),
      periodicDay: !!values.periodicDay ? parseInt(values.periodicDay) : 1,
    });
  }

  useLayoutEffect(() => {
    getUserTypes();
    getTypes();
    getPeriods();
    getShift(auth.user.project.id);
  }, []);

  useEffect(() => {
    const _data = (dataAddQuest?.data as unknown) as any;
    if (statusAddQuest === "fulfilled" && _data.success) {
      toast({
        title: "Başarılı!",
        description: "Soru başarıyla oluşturuldu.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      props.onSuccess && props.onSuccess();
      props.onClose();
    } else if (statusAddQuest === "fulfilled" && !_data.success) {
      toast({
        title: "Hata Oluştu!",
        description: _data.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [statusAddQuest]);

  const typeList = (dataTypes?.data as unknown) as UserTypeListResponse;
  const typeUserList = (dataUserTypes?.data as unknown) as UserTypeListResponse;
  const periodList = (dataPeriods?.data as unknown) as UserTypeListResponse;
  const shiftList = (dataShifts?.data as unknown) as ProjectShiftListResponse;

  return (
    <DrawerContent w="500px">
      <DrawerCloseButton />
      <DrawerHeader>Soru Ekle</DrawerHeader>

      <Formik<Form>
        initialValues={{
          questionText: "",
          endValue: "",
          initialValue: "",
          questionTypeID: "",
          userTypeID: "",
          questionPeriodID: "",
          projectShiftID: "",
          periodicDay: "",
        }}
        onSubmit={onValid}
      >
        {({ values }) => (
          <Form>
            <DrawerBody maxH="calc(100vh - 250px)" overflowY="scroll">
              <FormControl>
                <FormLabel>Soru</FormLabel>
                <Field
                  as={Textarea}
                  name="questionText"
                  required
                  minLength="1"
                />
              </FormControl>
              <FormControl mt="3">
                <FormLabel>Soru Tipi</FormLabel>
                <Field
                  as={Select}
                  name="userTypeID"
                  placeholder="Soru Tipi Seçin"
                  required
                >
                  {statusUserTypes === "fulfilled" &&
                    typeUserList.data.map((_type, index) => (
                      <option value={_type.id}>{_type.name}</option>
                    ))}
                </Field>
              </FormControl>
              <FormControl mt="3">
                <FormLabel>Periyot</FormLabel>
                <Field
                  as={Select}
                  name="questionPeriodID"
                  placeholder="Periyot Seçin"
                  required
                >
                  {statusPeriods === "fulfilled" &&
                    periodList.data.map((_type, index) => (
                      <option key={index} value={_type.id}>
                        {_type.name}
                      </option>
                    ))}
                </Field>
              </FormControl>
              {values.questionPeriodID === "1" ? // <FormControl mt="3">
              //   <FormLabel>Vardiya</FormLabel>
              //   <Field
              //     as={Select}
              //     name="projectShiftID"
              //     placeholder="Vardiya Seç"
              //   >
              //     {statusShift === "fulfilled" &&
              //       shiftList.data
              //         .filter((_item) => !!_item.isActive)
              //         .map((_type, index) => (
              //           <option key={index} value={_type.id}>
              //             {_type.startHour} - {_type.endHour}
              //           </option>
              //         ))}
              //   </Field>
              // </FormControl>
              null : values.questionPeriodID === "2" ? (
                <FormControl mt="3">
                  <FormLabel>Periyot Günü</FormLabel>
                  <Field as={Select} name="periodicDay">
                    {DAYS.map((day, index) => (
                      <option value={index + 1} key={index}>
                        {day}
                      </option>
                    ))}
                  </Field>
                </FormControl>
              ) : values.questionPeriodID === "3" ? (
                <FormControl mt="3">
                  <FormLabel>Periyot Günü</FormLabel>
                  <Field as={Select} name="periodicDay" required>
                    {[...Array(28)].map((_, index) => (
                      <option value={index + 1} key={index}>
                        {index + 1}
                      </option>
                    ))}
                  </Field>
                </FormControl>
              ) : null}
              <FormControl mt="3">
                <FormLabel>Değer Tipi</FormLabel>
                <Field
                  as={Select}
                  name="questionTypeID"
                  placeholder="Değer Tipi Seçin"
                  required
                >
                  {statusTypes === "fulfilled" &&
                    typeList.data.map((_type, index) => (
                      <option value={_type.id}>{_type.name}</option>
                    ))}
                </Field>
              </FormControl>
              {parseInt(values.questionTypeID) > 2 &&
                parseInt(values.questionTypeID) !== 8 && (
                  <Fragment>
                    <FormControl mt="3">
                      <FormLabel>Başlangıç Değeri</FormLabel>
                      <Field
                        as={Input}
                        name="initialValue"
                        type="text"
                        required
                        minLength="1"
                      />
                    </FormControl>
                    <FormControl mt="3">
                      <FormLabel>Bitiş Değeri</FormLabel>
                      <Field as={Input} name="endValue" type="text" required />
                    </FormControl>
                  </Fragment>
                )}
            </DrawerBody>
            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={props.onClose}>
                Vazgeç
              </Button>
              <Button colorScheme="blue" type="submit">
                Oluştur
              </Button>
            </DrawerFooter>
          </Form>
        )}
      </Formik>
    </DrawerContent>
  );
}

export default AddQuestion;
