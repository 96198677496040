import React, { useEffect, useLayoutEffect } from "react";
import {
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Input,
  DrawerFooter,
  Button,
  FormControl,
  FormLabel,
  Textarea,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import { Hooks } from "services";
import { useRedux } from "hooks/redux-hooks";
import InputMask from "components/atoms/InputMask";

interface AddUserProps {
  onClose: () => void;
  projectId: number;
  onSuccess?: () => void;
}

interface Form {
  title: string;
  body: string;
}

function AddNotice(props: AddUserProps) {
  const [addItem, status] = Hooks.AddNotice();
  const [{ auth }] = useRedux();

  async function onValid(values: Form) {
    addItem({
      addUserID: auth.user.id,
      projectID: auth.user.project.id,
      ...values,
    });
  }

  useEffect(() => {
    if (status === "fulfilled") {
      props.onSuccess && props.onSuccess();
      props.onClose();
    }
  }, [status]);

  return (
    <DrawerContent>
      <DrawerCloseButton />
      <DrawerHeader>Yeni Duyuru Ekle</DrawerHeader>

      <Formik<Form>
        initialValues={{
          title: "",
          body: "",
        }}
        onSubmit={onValid}
      >
        <Form>
          <DrawerBody>
            <FormControl mt="3">
              <FormLabel>Baslik</FormLabel>
              <Field as={Input} name="title" />
            </FormControl>
            <FormControl mt="3">
              <FormLabel>Icerik</FormLabel>
              <Field as={Textarea} name="body" />
            </FormControl>
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={props.onClose}>
              Vazgeç
            </Button>
            <Button
              colorScheme="blue"
              type="submit"
              isLoading={status === "pending"}
            >
              Oluştur
            </Button>
          </DrawerFooter>
        </Form>
      </Formik>
    </DrawerContent>
  );
}

export default AddNotice;
