/* tslint:disable */
/* eslint-disable */
/**
 * Borsa Istanbul Lokasyon Kontrol
 * Here is all endpoints
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddCheckListDTO
 */
export interface AddCheckListDTO {
    /**
     * 
     * @type {number}
     * @memberof AddCheckListDTO
     */
    userTypeID?: number;
    /**
     * 
     * @type {number}
     * @memberof AddCheckListDTO
     */
    projectID?: number;
    /**
     * 
     * @type {string}
     * @memberof AddCheckListDTO
     */
    projectName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddCheckListDTO
     */
    controlAreaID?: number;
    /**
     * 
     * @type {number}
     * @memberof AddCheckListDTO
     */
    projectShiftID?: number;
    /**
     * 
     * @type {number}
     * @memberof AddCheckListDTO
     */
    controlPointID?: number;
    /**
     * 
     * @type {number}
     * @memberof AddCheckListDTO
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof AddCheckListDTO
     */
    questionPeriodID?: number;
    /**
     * 
     * @type {number}
     * @memberof AddCheckListDTO
     */
    projectQuestionID?: number;
    /**
     * 
     * @type {string}
     * @memberof AddCheckListDTO
     */
    value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddCheckListDTO
     */
    userAnswer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddCheckListDTO
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddCheckListDTO
     */
    photoPath?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddCheckListDTO
     */
    status?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AddCheckListDTO
     */
    question?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddCheckListDTO
     */
    questionType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddCheckListDTO
     */
    questionPeriod?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddCheckListDTO
     */
    controlAreaName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddCheckListDTO
     */
    controlPointName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddCheckListDTO
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddCheckListDTO
     */
    initialValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddCheckListDTO
     */
    endValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddCheckListDTO
     */
    shiftName?: string | null;
}
/**
 * 
 * @export
 * @interface AddNoticeDTO
 */
export interface AddNoticeDTO {
    /**
     * 
     * @type {number}
     * @memberof AddNoticeDTO
     */
    projectID?: number;
    /**
     * 
     * @type {number}
     * @memberof AddNoticeDTO
     */
    addUserID?: number;
    /**
     * 
     * @type {string}
     * @memberof AddNoticeDTO
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddNoticeDTO
     */
    body?: string | null;
}
/**
 * 
 * @export
 * @interface AddProjectControlPointDTO
 */
export interface AddProjectControlPointDTO {
    /**
     * 
     * @type {number}
     * @memberof AddProjectControlPointDTO
     */
    projectID?: number;
    /**
     * 
     * @type {number}
     * @memberof AddProjectControlPointDTO
     */
    controlAreaID?: number;
    /**
     * 
     * @type {string}
     * @memberof AddProjectControlPointDTO
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface AddProjectShiftDTO
 */
export interface AddProjectShiftDTO {
    /**
     * 
     * @type {number}
     * @memberof AddProjectShiftDTO
     */
    projectID?: number;
    /**
     * 
     * @type {string}
     * @memberof AddProjectShiftDTO
     */
    startHour?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddProjectShiftDTO
     */
    endHour?: string | null;
}
/**
 * 
 * @export
 * @interface AddQuestionDTO
 */
export interface AddQuestionDTO {
    /**
     * 
     * @type {number}
     * @memberof AddQuestionDTO
     */
    projectID?: number;
    /**
     * 
     * @type {number}
     * @memberof AddQuestionDTO
     */
    controlAreaID?: number;
    /**
     * 
     * @type {number}
     * @memberof AddQuestionDTO
     */
    controlPointID?: number;
    /**
     * 
     * @type {number}
     * @memberof AddQuestionDTO
     */
    userTypeID?: number;
    /**
     * 
     * @type {number}
     * @memberof AddQuestionDTO
     */
    questionTypeID?: number;
    /**
     * 
     * @type {number}
     * @memberof AddQuestionDTO
     */
    questionPeriodID?: number;
    /**
     * 
     * @type {number}
     * @memberof AddQuestionDTO
     */
    periodicDay?: number;
    /**
     * 
     * @type {string}
     * @memberof AddQuestionDTO
     */
    questionText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddQuestionDTO
     */
    initialValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddQuestionDTO
     */
    endValue?: string | null;
}
/**
 * 
 * @export
 * @interface AddUserDTO
 */
export interface AddUserDTO {
    /**
     * 
     * @type {number}
     * @memberof AddUserDTO
     */
    projectID?: number;
    /**
     * 
     * @type {number}
     * @memberof AddUserDTO
     */
    userRoleID?: number;
    /**
     * 
     * @type {number}
     * @memberof AddUserDTO
     */
    userTypeID?: number;
    /**
     * 
     * @type {number}
     * @memberof AddUserDTO
     */
    companyID?: number;
    /**
     * 
     * @type {string}
     * @memberof AddUserDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddUserDTO
     */
    surname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddUserDTO
     */
    username?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddUserDTO
     */
    password?: string | null;
}
/**
 * 
 * @export
 * @interface ControlPanelDailyQuestion
 */
export interface ControlPanelDailyQuestion {
    /**
     * 
     * @type {string}
     * @memberof ControlPanelDailyQuestion
     */
    shift?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlPanelDailyQuestion
     */
    dailyNegative?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlPanelDailyQuestion
     */
    dailyPositive?: number;
}
/**
 * 
 * @export
 * @interface ControlPanelResponse
 */
export interface ControlPanelResponse {
    /**
     * 
     * @type {number}
     * @memberof ControlPanelResponse
     */
    weeklyNegative?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlPanelResponse
     */
    weeklyPositive?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlPanelResponse
     */
    monthlyNegative?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlPanelResponse
     */
    monthlyPositive?: number;
    /**
     * 
     * @type {Array<ControlPanelDailyQuestion>}
     * @memberof ControlPanelResponse
     */
    daily?: Array<ControlPanelDailyQuestion> | null;
}
/**
 * 
 * @export
 * @interface ControlPanelResponseDataResult
 */
export interface ControlPanelResponseDataResult {
    /**
     * 
     * @type {boolean}
     * @memberof ControlPanelResponseDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlPanelResponseDataResult
     */
    message?: string | null;
    /**
     * 
     * @type {ControlPanelResponse}
     * @memberof ControlPanelResponseDataResult
     */
    data?: ControlPanelResponse;
}
/**
 * 
 * @export
 * @interface Notice
 */
export interface Notice {
    /**
     * 
     * @type {number}
     * @memberof Notice
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof Notice
     */
    projectID?: number;
    /**
     * 
     * @type {number}
     * @memberof Notice
     */
    addUserID?: number;
    /**
     * 
     * @type {string}
     * @memberof Notice
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Notice
     */
    body?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Notice
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Notice
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface NoticeListDTO
 */
export interface NoticeListDTO {
    /**
     * 
     * @type {number}
     * @memberof NoticeListDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof NoticeListDTO
     */
    projectID?: number;
    /**
     * 
     * @type {number}
     * @memberof NoticeListDTO
     */
    addUserID?: number;
    /**
     * 
     * @type {string}
     * @memberof NoticeListDTO
     */
    addUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NoticeListDTO
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NoticeListDTO
     */
    body?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NoticeListDTO
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NoticeListDTO
     */
    createDate?: string | null;
}
/**
 * 
 * @export
 * @interface NoticeListDTOListDataResult
 */
export interface NoticeListDTOListDataResult {
    /**
     * 
     * @type {boolean}
     * @memberof NoticeListDTOListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NoticeListDTOListDataResult
     */
    message?: string | null;
    /**
     * 
     * @type {Array<NoticeListDTO>}
     * @memberof NoticeListDTOListDataResult
     */
    data?: Array<NoticeListDTO> | null;
}
/**
 * 
 * @export
 * @interface ProjectControlArea
 */
export interface ProjectControlArea {
    /**
     * 
     * @type {number}
     * @memberof ProjectControlArea
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectControlArea
     */
    projectID?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectControlArea
     */
    addUserID?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectControlArea
     */
    barcode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectControlArea
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectControlArea
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectControlArea
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface ProjectControlPoint
 */
export interface ProjectControlPoint {
    /**
     * 
     * @type {number}
     * @memberof ProjectControlPoint
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectControlPoint
     */
    projectID?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectControlPoint
     */
    controlAreaID?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectControlPoint
     */
    addUserID?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectControlPoint
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectControlPoint
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectControlPoint
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface ProjectMail
 */
export interface ProjectMail {
    /**
     * 
     * @type {number}
     * @memberof ProjectMail
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectMail
     */
    userTypeID?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectMail
     */
    projectID?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectMail
     */
    addUserID?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectMail
     */
    mailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectMail
     */
    subject?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectMail
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectMail
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface ProjectMailResponse
 */
export interface ProjectMailResponse {
    /**
     * 
     * @type {number}
     * @memberof ProjectMailResponse
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectMailResponse
     */
    userTypeID?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectMailResponse
     */
    userTypeName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectMailResponse
     */
    projectID?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectMailResponse
     */
    addUserID?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectMailResponse
     */
    addUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectMailResponse
     */
    mailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectMailResponse
     */
    subject?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectMailResponse
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectMailResponse
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface ProjectMailResponseListDataResult
 */
export interface ProjectMailResponseListDataResult {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectMailResponseListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectMailResponseListDataResult
     */
    message?: string | null;
    /**
     * 
     * @type {Array<ProjectMailResponse>}
     * @memberof ProjectMailResponseListDataResult
     */
    data?: Array<ProjectMailResponse> | null;
}
/**
 * 
 * @export
 * @interface ProjectQuestion
 */
export interface ProjectQuestion {
    /**
     * 
     * @type {number}
     * @memberof ProjectQuestion
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectQuestion
     */
    projectID?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectQuestion
     */
    controlAreaID?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectQuestion
     */
    controlPointID?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectQuestion
     */
    userTypeID?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectQuestion
     */
    questionTypeID?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectQuestion
     */
    questionPeriodID?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectQuestion
     */
    addUserID?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectQuestion
     */
    periodicDay?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectQuestion
     */
    questionText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectQuestion
     */
    initialValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectQuestion
     */
    endValue?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectQuestion
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectQuestion
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface ProjectShift
 */
export interface ProjectShift {
    /**
     * 
     * @type {number}
     * @memberof ProjectShift
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectShift
     */
    projectID?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectShift
     */
    addUserID?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectShift
     */
    startHour?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectShift
     */
    endHour?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectShift
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectShift
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface ReportResponseDTO
 */
export interface ReportResponseDTO {
    /**
     * 
     * @type {boolean}
     * @memberof ReportResponseDTO
     */
    isChecked?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReportResponseDTO
     */
    questionID?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportResponseDTO
     */
    controlAreaName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportResponseDTO
     */
    controlPointName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportResponseDTO
     */
    userTypeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportResponseDTO
     */
    questionTypeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportResponseDTO
     */
    questionPeriodName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReportResponseDTO
     */
    periodicDay?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportResponseDTO
     */
    questionText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportResponseDTO
     */
    initialValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportResponseDTO
     */
    endValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportResponseDTO
     */
    checkedUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportResponseDTO
     */
    userAnswer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportResponseDTO
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportResponseDTO
     */
    photoPath?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReportResponseDTO
     */
    status?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ReportResponseDTO
     */
    createDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReportResponseDTO
     */
    shiftID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReportResponseDTO
     */
    shiftName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportResponseDTO
     */
    barcode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReportResponseDTO
     */
    userTypeId?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportResponseDTO
     */
    controlDate?: string | null;
}
/**
 * 
 * @export
 * @interface ReportResponseDTOListDataResult
 */
export interface ReportResponseDTOListDataResult {
    /**
     * 
     * @type {boolean}
     * @memberof ReportResponseDTOListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReportResponseDTOListDataResult
     */
    message?: string | null;
    /**
     * 
     * @type {Array<ReportResponseDTO>}
     * @memberof ReportResponseDTOListDataResult
     */
    data?: Array<ReportResponseDTO> | null;
}
/**
 * 
 * @export
 * @interface Result
 */
export interface Result {
    /**
     * 
     * @type {boolean}
     * @memberof Result
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Result
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateUserDTO
 */
export interface UpdateUserDTO {
    /**
     * 
     * @type {number}
     * @memberof UpdateUserDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateUserDTO
     */
    userRoleID?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateUserDTO
     */
    userTypeID?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDTO
     */
    surname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDTO
     */
    password?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserDTO
     */
    notificationEnabled?: boolean;
}

/**
 * ChecklistApi - axios parameter creator
 * @export
 */
export const ChecklistApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Kontrolü gerçekleştirir
         * @param {AddCheckListDTO} [addCheckListDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChecklistAddCheckPost: async (addCheckListDTO?: AddCheckListDTO, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Checklist/AddCheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addCheckListDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Günlük soruları listeler
         * @param {number} [projectId] 
         * @param {string} [barcode] 
         * @param {number} [userTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChecklistGetDailyQuestionGet: async (projectId?: number, barcode?: string, userTypeId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Checklist/GetDailyQuestion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (barcode !== undefined) {
                localVarQueryParameter['barcode'] = barcode;
            }

            if (userTypeId !== undefined) {
                localVarQueryParameter['userTypeId'] = userTypeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary günlük kontrol edilmemiş soruları listeler
         * @param {number} [projectId] 
         * @param {number} [userTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChecklistGetDailyUncheckedQuestionsGet: async (projectId?: number, userTypeId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Checklist/GetDailyUncheckedQuestions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (userTypeId !== undefined) {
                localVarQueryParameter['userTypeId'] = userTypeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChecklistApi - functional programming interface
 * @export
 */
export const ChecklistApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChecklistApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Kontrolü gerçekleştirir
         * @param {AddCheckListDTO} [addCheckListDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiChecklistAddCheckPost(addCheckListDTO?: AddCheckListDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiChecklistAddCheckPost(addCheckListDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Günlük soruları listeler
         * @param {number} [projectId] 
         * @param {string} [barcode] 
         * @param {number} [userTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiChecklistGetDailyQuestionGet(projectId?: number, barcode?: string, userTypeId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiChecklistGetDailyQuestionGet(projectId, barcode, userTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary günlük kontrol edilmemiş soruları listeler
         * @param {number} [projectId] 
         * @param {number} [userTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiChecklistGetDailyUncheckedQuestionsGet(projectId?: number, userTypeId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiChecklistGetDailyUncheckedQuestionsGet(projectId, userTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChecklistApi - factory interface
 * @export
 */
export const ChecklistApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChecklistApiFp(configuration)
    return {
        /**
         * 
         * @summary Kontrolü gerçekleştirir
         * @param {AddCheckListDTO} [addCheckListDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChecklistAddCheckPost(addCheckListDTO?: AddCheckListDTO, options?: any): AxiosPromise<void> {
            return localVarFp.apiChecklistAddCheckPost(addCheckListDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Günlük soruları listeler
         * @param {number} [projectId] 
         * @param {string} [barcode] 
         * @param {number} [userTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChecklistGetDailyQuestionGet(projectId?: number, barcode?: string, userTypeId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiChecklistGetDailyQuestionGet(projectId, barcode, userTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary günlük kontrol edilmemiş soruları listeler
         * @param {number} [projectId] 
         * @param {number} [userTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChecklistGetDailyUncheckedQuestionsGet(projectId?: number, userTypeId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiChecklistGetDailyUncheckedQuestionsGet(projectId, userTypeId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChecklistApi - object-oriented interface
 * @export
 * @class ChecklistApi
 * @extends {BaseAPI}
 */
export class ChecklistApi extends BaseAPI {
    /**
     * 
     * @summary Kontrolü gerçekleştirir
     * @param {AddCheckListDTO} [addCheckListDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public apiChecklistAddCheckPost(addCheckListDTO?: AddCheckListDTO, options?: any) {
        return ChecklistApiFp(this.configuration).apiChecklistAddCheckPost(addCheckListDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Günlük soruları listeler
     * @param {number} [projectId] 
     * @param {string} [barcode] 
     * @param {number} [userTypeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public apiChecklistGetDailyQuestionGet(projectId?: number, barcode?: string, userTypeId?: number, options?: any) {
        return ChecklistApiFp(this.configuration).apiChecklistGetDailyQuestionGet(projectId, barcode, userTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary günlük kontrol edilmemiş soruları listeler
     * @param {number} [projectId] 
     * @param {number} [userTypeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public apiChecklistGetDailyUncheckedQuestionsGet(projectId?: number, userTypeId?: number, options?: any) {
        return ChecklistApiFp(this.configuration).apiChecklistGetDailyUncheckedQuestionsGet(projectId, userTypeId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ControlAreaApi - axios parameter creator
 * @export
 */
export const ControlAreaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary control bölgesi ekler
         * @param {number} [projectId] 
         * @param {string} [barcode] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlAreaAddControlAreaGet: async (projectId?: number, barcode?: string, name?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ControlArea/AddControlArea`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (barcode !== undefined) {
                localVarQueryParameter['barcode'] = barcode;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary control nokası ekler
         * @param {AddProjectControlPointDTO} [addProjectControlPointDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlAreaAddControlPointPost: async (addProjectControlPointDTO?: AddProjectControlPointDTO, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ControlArea/AddControlPoint`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addProjectControlPointDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary control bölgesi listesini verir
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlAreaGetControlAreaListGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ControlArea/GetControlAreaList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary control noktası listesini verir
         * @param {number} [controlAreaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlAreaGetControlPointListGet: async (controlAreaId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ControlArea/GetControlPointList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (controlAreaId !== undefined) {
                localVarQueryParameter['controlAreaId'] = controlAreaId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary control bölgesini günceller
         * @param {ProjectControlArea} [projectControlArea] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlAreaUpdateControlAreaPost: async (projectControlArea?: ProjectControlArea, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ControlArea/UpdateControlArea`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectControlArea, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary control noktasını günceller
         * @param {ProjectControlPoint} [projectControlPoint] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlAreaUpdateControlPointPost: async (projectControlPoint?: ProjectControlPoint, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ControlArea/UpdateControlPoint`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectControlPoint, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ControlAreaApi - functional programming interface
 * @export
 */
export const ControlAreaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ControlAreaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary control bölgesi ekler
         * @param {number} [projectId] 
         * @param {string} [barcode] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiControlAreaAddControlAreaGet(projectId?: number, barcode?: string, name?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiControlAreaAddControlAreaGet(projectId, barcode, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary control nokası ekler
         * @param {AddProjectControlPointDTO} [addProjectControlPointDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiControlAreaAddControlPointPost(addProjectControlPointDTO?: AddProjectControlPointDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiControlAreaAddControlPointPost(addProjectControlPointDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary control bölgesi listesini verir
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiControlAreaGetControlAreaListGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiControlAreaGetControlAreaListGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary control noktası listesini verir
         * @param {number} [controlAreaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiControlAreaGetControlPointListGet(controlAreaId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiControlAreaGetControlPointListGet(controlAreaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary control bölgesini günceller
         * @param {ProjectControlArea} [projectControlArea] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiControlAreaUpdateControlAreaPost(projectControlArea?: ProjectControlArea, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiControlAreaUpdateControlAreaPost(projectControlArea, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary control noktasını günceller
         * @param {ProjectControlPoint} [projectControlPoint] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiControlAreaUpdateControlPointPost(projectControlPoint?: ProjectControlPoint, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiControlAreaUpdateControlPointPost(projectControlPoint, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ControlAreaApi - factory interface
 * @export
 */
export const ControlAreaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ControlAreaApiFp(configuration)
    return {
        /**
         * 
         * @summary control bölgesi ekler
         * @param {number} [projectId] 
         * @param {string} [barcode] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlAreaAddControlAreaGet(projectId?: number, barcode?: string, name?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiControlAreaAddControlAreaGet(projectId, barcode, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary control nokası ekler
         * @param {AddProjectControlPointDTO} [addProjectControlPointDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlAreaAddControlPointPost(addProjectControlPointDTO?: AddProjectControlPointDTO, options?: any): AxiosPromise<void> {
            return localVarFp.apiControlAreaAddControlPointPost(addProjectControlPointDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary control bölgesi listesini verir
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlAreaGetControlAreaListGet(projectId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiControlAreaGetControlAreaListGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary control noktası listesini verir
         * @param {number} [controlAreaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlAreaGetControlPointListGet(controlAreaId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiControlAreaGetControlPointListGet(controlAreaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary control bölgesini günceller
         * @param {ProjectControlArea} [projectControlArea] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlAreaUpdateControlAreaPost(projectControlArea?: ProjectControlArea, options?: any): AxiosPromise<void> {
            return localVarFp.apiControlAreaUpdateControlAreaPost(projectControlArea, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary control noktasını günceller
         * @param {ProjectControlPoint} [projectControlPoint] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlAreaUpdateControlPointPost(projectControlPoint?: ProjectControlPoint, options?: any): AxiosPromise<void> {
            return localVarFp.apiControlAreaUpdateControlPointPost(projectControlPoint, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ControlAreaApi - object-oriented interface
 * @export
 * @class ControlAreaApi
 * @extends {BaseAPI}
 */
export class ControlAreaApi extends BaseAPI {
    /**
     * 
     * @summary control bölgesi ekler
     * @param {number} [projectId] 
     * @param {string} [barcode] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlAreaApi
     */
    public apiControlAreaAddControlAreaGet(projectId?: number, barcode?: string, name?: string, options?: any) {
        return ControlAreaApiFp(this.configuration).apiControlAreaAddControlAreaGet(projectId, barcode, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary control nokası ekler
     * @param {AddProjectControlPointDTO} [addProjectControlPointDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlAreaApi
     */
    public apiControlAreaAddControlPointPost(addProjectControlPointDTO?: AddProjectControlPointDTO, options?: any) {
        return ControlAreaApiFp(this.configuration).apiControlAreaAddControlPointPost(addProjectControlPointDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary control bölgesi listesini verir
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlAreaApi
     */
    public apiControlAreaGetControlAreaListGet(projectId?: number, options?: any) {
        return ControlAreaApiFp(this.configuration).apiControlAreaGetControlAreaListGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary control noktası listesini verir
     * @param {number} [controlAreaId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlAreaApi
     */
    public apiControlAreaGetControlPointListGet(controlAreaId?: number, options?: any) {
        return ControlAreaApiFp(this.configuration).apiControlAreaGetControlPointListGet(controlAreaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary control bölgesini günceller
     * @param {ProjectControlArea} [projectControlArea] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlAreaApi
     */
    public apiControlAreaUpdateControlAreaPost(projectControlArea?: ProjectControlArea, options?: any) {
        return ControlAreaApiFp(this.configuration).apiControlAreaUpdateControlAreaPost(projectControlArea, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary control noktasını günceller
     * @param {ProjectControlPoint} [projectControlPoint] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlAreaApi
     */
    public apiControlAreaUpdateControlPointPost(projectControlPoint?: ProjectControlPoint, options?: any) {
        return ControlAreaApiFp(this.configuration).apiControlAreaUpdateControlPointPost(projectControlPoint, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EndOfDayNotificationApi - axios parameter creator
 * @export
 */
export const EndOfDayNotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Vardiya sonu kontrol edilmemiş sorular
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEndOfDayNotificationGetUnCheckedQuestionGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/EndOfDayNotification/GetUnCheckedQuestion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EndOfDayNotificationApi - functional programming interface
 * @export
 */
export const EndOfDayNotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EndOfDayNotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Vardiya sonu kontrol edilmemiş sorular
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEndOfDayNotificationGetUnCheckedQuestionGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEndOfDayNotificationGetUnCheckedQuestionGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EndOfDayNotificationApi - factory interface
 * @export
 */
export const EndOfDayNotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EndOfDayNotificationApiFp(configuration)
    return {
        /**
         * 
         * @summary Vardiya sonu kontrol edilmemiş sorular
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEndOfDayNotificationGetUnCheckedQuestionGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiEndOfDayNotificationGetUnCheckedQuestionGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EndOfDayNotificationApi - object-oriented interface
 * @export
 * @class EndOfDayNotificationApi
 * @extends {BaseAPI}
 */
export class EndOfDayNotificationApi extends BaseAPI {
    /**
     * 
     * @summary Vardiya sonu kontrol edilmemiş sorular
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EndOfDayNotificationApi
     */
    public apiEndOfDayNotificationGetUnCheckedQuestionGet(options?: any) {
        return EndOfDayNotificationApiFp(this.configuration).apiEndOfDayNotificationGetUnCheckedQuestionGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NoticeApi - axios parameter creator
 * @export
 */
export const NoticeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Projeye yeni bir duyuru ekler
         * @param {AddNoticeDTO} [addNoticeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNoticeAddNoticePost: async (addNoticeDTO?: AddNoticeDTO, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Notice/AddNotice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addNoticeDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Projeye ait duyurular listelenir
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNoticeGetAllNoticeGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Notice/GetAllNotice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Duyuru güncellendi
         * @param {Notice} [notice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNoticeUpdateNoticePost: async (notice?: Notice, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Notice/UpdateNotice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notice, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NoticeApi - functional programming interface
 * @export
 */
export const NoticeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NoticeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Projeye yeni bir duyuru ekler
         * @param {AddNoticeDTO} [addNoticeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNoticeAddNoticePost(addNoticeDTO?: AddNoticeDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNoticeAddNoticePost(addNoticeDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Projeye ait duyurular listelenir
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNoticeGetAllNoticeGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoticeListDTOListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNoticeGetAllNoticeGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Duyuru güncellendi
         * @param {Notice} [notice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNoticeUpdateNoticePost(notice?: Notice, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNoticeUpdateNoticePost(notice, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NoticeApi - factory interface
 * @export
 */
export const NoticeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NoticeApiFp(configuration)
    return {
        /**
         * 
         * @summary Projeye yeni bir duyuru ekler
         * @param {AddNoticeDTO} [addNoticeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNoticeAddNoticePost(addNoticeDTO?: AddNoticeDTO, options?: any): AxiosPromise<Result> {
            return localVarFp.apiNoticeAddNoticePost(addNoticeDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Projeye ait duyurular listelenir
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNoticeGetAllNoticeGet(projectId?: number, options?: any): AxiosPromise<NoticeListDTOListDataResult> {
            return localVarFp.apiNoticeGetAllNoticeGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Duyuru güncellendi
         * @param {Notice} [notice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNoticeUpdateNoticePost(notice?: Notice, options?: any): AxiosPromise<Result> {
            return localVarFp.apiNoticeUpdateNoticePost(notice, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NoticeApi - object-oriented interface
 * @export
 * @class NoticeApi
 * @extends {BaseAPI}
 */
export class NoticeApi extends BaseAPI {
    /**
     * 
     * @summary Projeye yeni bir duyuru ekler
     * @param {AddNoticeDTO} [addNoticeDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public apiNoticeAddNoticePost(addNoticeDTO?: AddNoticeDTO, options?: any) {
        return NoticeApiFp(this.configuration).apiNoticeAddNoticePost(addNoticeDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Projeye ait duyurular listelenir
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public apiNoticeGetAllNoticeGet(projectId?: number, options?: any) {
        return NoticeApiFp(this.configuration).apiNoticeGetAllNoticeGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Duyuru güncellendi
     * @param {Notice} [notice] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public apiNoticeUpdateNoticePost(notice?: Notice, options?: any) {
        return NoticeApiFp(this.configuration).apiNoticeUpdateNoticePost(notice, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Projeye yeni mail adresi ekler
         * @param {number} [projectId] 
         * @param {string} [mailAddress] 
         * @param {number} [userTypeId] 
         * @param {string} [subject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectAddProjectMailGet: async (projectId?: number, mailAddress?: string, userTypeId?: number, subject?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/AddProjectMail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (mailAddress !== undefined) {
                localVarQueryParameter['mailAddress'] = mailAddress;
            }

            if (userTypeId !== undefined) {
                localVarQueryParameter['userTypeId'] = userTypeId;
            }

            if (subject !== undefined) {
                localVarQueryParameter['subject'] = subject;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Projeye  yeni vardiya ekler
         * @param {AddProjectShiftDTO} [addProjectShiftDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectAddProjectShiftPost: async (addProjectShiftDTO?: AddProjectShiftDTO, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/AddProjectShift`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addProjectShiftDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Yetki var ise proje listesini döner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectGetProjectListGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProjectList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Projeye ait mail adresi listesini gösterir
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectGetProjectMailListGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProjectMailList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Projeye ait vardiya listesini gösterir
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectGetProjectShiftListGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProjectShiftList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Projeye ait mail adresini günceller
         * @param {ProjectMail} [projectMail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectUpdateProjectMailPost: async (projectMail?: ProjectMail, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/UpdateProjectMail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectMail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Projeye  ait vardiyayı günceller
         * @param {ProjectShift} [projectShift] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectUpdateProjectShiftPost: async (projectShift?: ProjectShift, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/UpdateProjectShift`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectShift, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Projeye yeni mail adresi ekler
         * @param {number} [projectId] 
         * @param {string} [mailAddress] 
         * @param {number} [userTypeId] 
         * @param {string} [subject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProjectAddProjectMailGet(projectId?: number, mailAddress?: string, userTypeId?: number, subject?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProjectAddProjectMailGet(projectId, mailAddress, userTypeId, subject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Projeye  yeni vardiya ekler
         * @param {AddProjectShiftDTO} [addProjectShiftDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProjectAddProjectShiftPost(addProjectShiftDTO?: AddProjectShiftDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProjectAddProjectShiftPost(addProjectShiftDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Yetki var ise proje listesini döner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProjectGetProjectListGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProjectGetProjectListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Projeye ait mail adresi listesini gösterir
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProjectGetProjectMailListGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectMailResponseListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProjectGetProjectMailListGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Projeye ait vardiya listesini gösterir
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProjectGetProjectShiftListGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProjectGetProjectShiftListGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Projeye ait mail adresini günceller
         * @param {ProjectMail} [projectMail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProjectUpdateProjectMailPost(projectMail?: ProjectMail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProjectUpdateProjectMailPost(projectMail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Projeye  ait vardiyayı günceller
         * @param {ProjectShift} [projectShift] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProjectUpdateProjectShiftPost(projectShift?: ProjectShift, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProjectUpdateProjectShiftPost(projectShift, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectApiFp(configuration)
    return {
        /**
         * 
         * @summary Projeye yeni mail adresi ekler
         * @param {number} [projectId] 
         * @param {string} [mailAddress] 
         * @param {number} [userTypeId] 
         * @param {string} [subject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectAddProjectMailGet(projectId?: number, mailAddress?: string, userTypeId?: number, subject?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiProjectAddProjectMailGet(projectId, mailAddress, userTypeId, subject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Projeye  yeni vardiya ekler
         * @param {AddProjectShiftDTO} [addProjectShiftDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectAddProjectShiftPost(addProjectShiftDTO?: AddProjectShiftDTO, options?: any): AxiosPromise<void> {
            return localVarFp.apiProjectAddProjectShiftPost(addProjectShiftDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Yetki var ise proje listesini döner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectGetProjectListGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiProjectGetProjectListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Projeye ait mail adresi listesini gösterir
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectGetProjectMailListGet(projectId?: number, options?: any): AxiosPromise<ProjectMailResponseListDataResult> {
            return localVarFp.apiProjectGetProjectMailListGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Projeye ait vardiya listesini gösterir
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectGetProjectShiftListGet(projectId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiProjectGetProjectShiftListGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Projeye ait mail adresini günceller
         * @param {ProjectMail} [projectMail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectUpdateProjectMailPost(projectMail?: ProjectMail, options?: any): AxiosPromise<void> {
            return localVarFp.apiProjectUpdateProjectMailPost(projectMail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Projeye  ait vardiyayı günceller
         * @param {ProjectShift} [projectShift] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectUpdateProjectShiftPost(projectShift?: ProjectShift, options?: any): AxiosPromise<void> {
            return localVarFp.apiProjectUpdateProjectShiftPost(projectShift, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI {
    /**
     * 
     * @summary Projeye yeni mail adresi ekler
     * @param {number} [projectId] 
     * @param {string} [mailAddress] 
     * @param {number} [userTypeId] 
     * @param {string} [subject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiProjectAddProjectMailGet(projectId?: number, mailAddress?: string, userTypeId?: number, subject?: string, options?: any) {
        return ProjectApiFp(this.configuration).apiProjectAddProjectMailGet(projectId, mailAddress, userTypeId, subject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Projeye  yeni vardiya ekler
     * @param {AddProjectShiftDTO} [addProjectShiftDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiProjectAddProjectShiftPost(addProjectShiftDTO?: AddProjectShiftDTO, options?: any) {
        return ProjectApiFp(this.configuration).apiProjectAddProjectShiftPost(addProjectShiftDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Yetki var ise proje listesini döner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiProjectGetProjectListGet(options?: any) {
        return ProjectApiFp(this.configuration).apiProjectGetProjectListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Projeye ait mail adresi listesini gösterir
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiProjectGetProjectMailListGet(projectId?: number, options?: any) {
        return ProjectApiFp(this.configuration).apiProjectGetProjectMailListGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Projeye ait vardiya listesini gösterir
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiProjectGetProjectShiftListGet(projectId?: number, options?: any) {
        return ProjectApiFp(this.configuration).apiProjectGetProjectShiftListGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Projeye ait mail adresini günceller
     * @param {ProjectMail} [projectMail] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiProjectUpdateProjectMailPost(projectMail?: ProjectMail, options?: any) {
        return ProjectApiFp(this.configuration).apiProjectUpdateProjectMailPost(projectMail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Projeye  ait vardiyayı günceller
     * @param {ProjectShift} [projectShift] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiProjectUpdateProjectShiftPost(projectShift?: ProjectShift, options?: any) {
        return ProjectApiFp(this.configuration).apiProjectUpdateProjectShiftPost(projectShift, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * QuestionApi - axios parameter creator
 * @export
 */
export const QuestionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Soru ekler
         * @param {AddQuestionDTO} [addQuestionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionAddQuestionPost: async (addQuestionDTO?: AddQuestionDTO, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Question/AddQuestion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addQuestionDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Yeni bir soru tipi ekler (derece, Amper gibi)
         * @param {string} [typeName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionAddQuestionTypeGet: async (typeName?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Question/AddQuestionType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (typeName !== undefined) {
                localVarQueryParameter['typeName'] = typeName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Projedeki tüm soruları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionGetAllQuestionListGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Question/GetAllQuestionList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Control noktasına ait soruları listeler
         * @param {number} [controlPointId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionGetQuestionListGet: async (controlPointId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Question/GetQuestionList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (controlPointId !== undefined) {
                localVarQueryParameter['controlPointId'] = controlPointId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Soru periyodlarını listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionGetQuestionPeriodListGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Question/GetQuestionPeriodList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Soru tiplerini listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionGetQuestionTypeListGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Question/GetQuestionTypeList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Soru günceller -- sadece sorunun textini, usertipini ve aktifliği güncellenir
         * @param {ProjectQuestion} [projectQuestion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionUpdateQuestionPost: async (projectQuestion?: ProjectQuestion, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Question/UpdateQuestion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectQuestion, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestionApi - functional programming interface
 * @export
 */
export const QuestionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuestionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Soru ekler
         * @param {AddQuestionDTO} [addQuestionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiQuestionAddQuestionPost(addQuestionDTO?: AddQuestionDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiQuestionAddQuestionPost(addQuestionDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Yeni bir soru tipi ekler (derece, Amper gibi)
         * @param {string} [typeName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiQuestionAddQuestionTypeGet(typeName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiQuestionAddQuestionTypeGet(typeName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Projedeki tüm soruları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiQuestionGetAllQuestionListGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiQuestionGetAllQuestionListGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Control noktasına ait soruları listeler
         * @param {number} [controlPointId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiQuestionGetQuestionListGet(controlPointId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiQuestionGetQuestionListGet(controlPointId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Soru periyodlarını listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiQuestionGetQuestionPeriodListGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiQuestionGetQuestionPeriodListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Soru tiplerini listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiQuestionGetQuestionTypeListGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiQuestionGetQuestionTypeListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Soru günceller -- sadece sorunun textini, usertipini ve aktifliği güncellenir
         * @param {ProjectQuestion} [projectQuestion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiQuestionUpdateQuestionPost(projectQuestion?: ProjectQuestion, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiQuestionUpdateQuestionPost(projectQuestion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QuestionApi - factory interface
 * @export
 */
export const QuestionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuestionApiFp(configuration)
    return {
        /**
         * 
         * @summary Soru ekler
         * @param {AddQuestionDTO} [addQuestionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionAddQuestionPost(addQuestionDTO?: AddQuestionDTO, options?: any): AxiosPromise<void> {
            return localVarFp.apiQuestionAddQuestionPost(addQuestionDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Yeni bir soru tipi ekler (derece, Amper gibi)
         * @param {string} [typeName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionAddQuestionTypeGet(typeName?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiQuestionAddQuestionTypeGet(typeName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Projedeki tüm soruları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionGetAllQuestionListGet(projectId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiQuestionGetAllQuestionListGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Control noktasına ait soruları listeler
         * @param {number} [controlPointId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionGetQuestionListGet(controlPointId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiQuestionGetQuestionListGet(controlPointId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Soru periyodlarını listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionGetQuestionPeriodListGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiQuestionGetQuestionPeriodListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Soru tiplerini listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionGetQuestionTypeListGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiQuestionGetQuestionTypeListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Soru günceller -- sadece sorunun textini, usertipini ve aktifliği güncellenir
         * @param {ProjectQuestion} [projectQuestion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionUpdateQuestionPost(projectQuestion?: ProjectQuestion, options?: any): AxiosPromise<void> {
            return localVarFp.apiQuestionUpdateQuestionPost(projectQuestion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuestionApi - object-oriented interface
 * @export
 * @class QuestionApi
 * @extends {BaseAPI}
 */
export class QuestionApi extends BaseAPI {
    /**
     * 
     * @summary Soru ekler
     * @param {AddQuestionDTO} [addQuestionDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public apiQuestionAddQuestionPost(addQuestionDTO?: AddQuestionDTO, options?: any) {
        return QuestionApiFp(this.configuration).apiQuestionAddQuestionPost(addQuestionDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Yeni bir soru tipi ekler (derece, Amper gibi)
     * @param {string} [typeName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public apiQuestionAddQuestionTypeGet(typeName?: string, options?: any) {
        return QuestionApiFp(this.configuration).apiQuestionAddQuestionTypeGet(typeName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Projedeki tüm soruları listeler
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public apiQuestionGetAllQuestionListGet(projectId?: number, options?: any) {
        return QuestionApiFp(this.configuration).apiQuestionGetAllQuestionListGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Control noktasına ait soruları listeler
     * @param {number} [controlPointId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public apiQuestionGetQuestionListGet(controlPointId?: number, options?: any) {
        return QuestionApiFp(this.configuration).apiQuestionGetQuestionListGet(controlPointId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Soru periyodlarını listeler
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public apiQuestionGetQuestionPeriodListGet(options?: any) {
        return QuestionApiFp(this.configuration).apiQuestionGetQuestionPeriodListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Soru tiplerini listeler
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public apiQuestionGetQuestionTypeListGet(options?: any) {
        return QuestionApiFp(this.configuration).apiQuestionGetQuestionTypeListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Soru günceller -- sadece sorunun textini, usertipini ve aktifliği güncellenir
     * @param {ProjectQuestion} [projectQuestion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public apiQuestionUpdateQuestionPost(projectQuestion?: ProjectQuestion, options?: any) {
        return QuestionApiFp(this.configuration).apiQuestionUpdateQuestionPost(projectQuestion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary kontrol paneli için rakamları getirir
         * @param {number} [projectId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportGetControlPanelGet: async (projectId?: number, startDate?: string, endDate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Report/GetControlPanel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary raporları listeler
         * @param {number} [projectId] 
         * @param {number} [questionPeriodId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportGetDailyReportByDateGet: async (projectId?: number, questionPeriodId?: number, startDate?: string, endDate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Report/GetDailyReportByDate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (questionPeriodId !== undefined) {
                localVarQueryParameter['questionPeriodId'] = questionPeriodId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary kontrol paneli için rakamları getirir
         * @param {number} [projectId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportGetControlPanelGet(projectId?: number, startDate?: string, endDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlPanelResponseDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportGetControlPanelGet(projectId, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary raporları listeler
         * @param {number} [projectId] 
         * @param {number} [questionPeriodId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportGetDailyReportByDateGet(projectId?: number, questionPeriodId?: number, startDate?: string, endDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportResponseDTOListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportGetDailyReportByDateGet(projectId, questionPeriodId, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportApiFp(configuration)
    return {
        /**
         * 
         * @summary kontrol paneli için rakamları getirir
         * @param {number} [projectId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportGetControlPanelGet(projectId?: number, startDate?: string, endDate?: string, options?: any): AxiosPromise<ControlPanelResponseDataResult> {
            return localVarFp.apiReportGetControlPanelGet(projectId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary raporları listeler
         * @param {number} [projectId] 
         * @param {number} [questionPeriodId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportGetDailyReportByDateGet(projectId?: number, questionPeriodId?: number, startDate?: string, endDate?: string, options?: any): AxiosPromise<ReportResponseDTOListDataResult> {
            return localVarFp.apiReportGetDailyReportByDateGet(projectId, questionPeriodId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
    /**
     * 
     * @summary kontrol paneli için rakamları getirir
     * @param {number} [projectId] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportGetControlPanelGet(projectId?: number, startDate?: string, endDate?: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportGetControlPanelGet(projectId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary raporları listeler
     * @param {number} [projectId] 
     * @param {number} [questionPeriodId] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportGetDailyReportByDateGet(projectId?: number, questionPeriodId?: number, startDate?: string, endDate?: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportGetDailyReportByDateGet(projectId, questionPeriodId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary kullanıcıyı projeye dahil eder
         * @param {number} [userId] 
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAddUserInProjectGet: async (userId?: number, projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/AddUserInProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary kullanıcı ekler  ProjectID = kullanıcıyı bir projeye atamak için kullanıyoruz , Giriş yaptığın projeyi gönder
         * @param {AddUserDTO} [addUserDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAddUserPost: async (addUserDTO?: AddUserDTO, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/AddUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addUserDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kullanıcı kendi şifresini değiştirir
         * @param {number} [userId] 
         * @param {string} [oldPassword] 
         * @param {string} [newPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserChanceUserPasswordGet: async (userId?: number, oldPassword?: string, newPassword?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/ChanceUserPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (oldPassword !== undefined) {
                localVarQueryParameter['oldPassword'] = oldPassword;
            }

            if (newPassword !== undefined) {
                localVarQueryParameter['newPassword'] = newPassword;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary kullanıcıyı projeden çıkartır
         * @param {number} [userId] 
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserDeleteUserInProjectGet: async (userId?: number, projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/DeleteUserInProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Projede olan kullanıcıları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetProjectInUserGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetProjectInUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User Detayını verir
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUserDetailByIdGet: async (userId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetUserDetailById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Yetki var ise kullanıcı listesini döner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUserListGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetUserList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kullanıcıya ait projeleri listeler
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUserProjectListGet: async (userId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetUserProjectList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kullanıcı rol listesini gösterir
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUserRoleListGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetUserRoleList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kullanıcı Tip listesini gösterir
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUserTypeListGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetUserTypeList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login
         * @param {string} [username] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserLoginGet: async (username?: string, password?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [projectID] 
         * @param {number} [userID] 
         * @param {number} [appVersion] 
         * @param {string} [platform] 
         * @param {string} [model] 
         * @param {string} [machine] 
         * @param {string} [notificationToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSplashControlGet: async (projectID?: number, userID?: number, appVersion?: number, platform?: string, model?: string, machine?: string, notificationToken?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/splashControl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }

            if (userID !== undefined) {
                localVarQueryParameter['UserID'] = userID;
            }

            if (appVersion !== undefined) {
                localVarQueryParameter['AppVersion'] = appVersion;
            }

            if (platform !== undefined) {
                localVarQueryParameter['Platform'] = platform;
            }

            if (model !== undefined) {
                localVarQueryParameter['Model'] = model;
            }

            if (machine !== undefined) {
                localVarQueryParameter['Machine'] = machine;
            }

            if (notificationToken !== undefined) {
                localVarQueryParameter['notificationToken'] = notificationToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  Kullanıcıya yeni şifre atanır  bunu sadece admin veya yönetici kullanabilmeli
         * @param {number} [userId] 
         * @param {string} [newPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUpdateUserPasswordGet: async (userId?: number, newPassword?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/UpdateUserPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (newPassword !== undefined) {
                localVarQueryParameter['newPassword'] = newPassword;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  kullanıcı günceller  Şifre zorunlu değildir, gönderirsen günceller, göndermezsen güncellemez
         * @param {UpdateUserDTO} [updateUserDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUpdateUserPost: async (updateUserDTO?: UpdateUserDTO, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/UpdateUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary kullanıcıyı aktif hale getirir
         * @param {number} [userId] 
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserActiveGet: async (userId?: number, projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/UserActive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary kullanıcıyı pasif yapar
         * @param {number} [userId] 
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserPassiveGet: async (userId?: number, projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/UserPassive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary kullanıcıyı projeye dahil eder
         * @param {number} [userId] 
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserAddUserInProjectGet(userId?: number, projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserAddUserInProjectGet(userId, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary kullanıcı ekler  ProjectID = kullanıcıyı bir projeye atamak için kullanıyoruz , Giriş yaptığın projeyi gönder
         * @param {AddUserDTO} [addUserDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserAddUserPost(addUserDTO?: AddUserDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserAddUserPost(addUserDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Kullanıcı kendi şifresini değiştirir
         * @param {number} [userId] 
         * @param {string} [oldPassword] 
         * @param {string} [newPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserChanceUserPasswordGet(userId?: number, oldPassword?: string, newPassword?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserChanceUserPasswordGet(userId, oldPassword, newPassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary kullanıcıyı projeden çıkartır
         * @param {number} [userId] 
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserDeleteUserInProjectGet(userId?: number, projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserDeleteUserInProjectGet(userId, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Projede olan kullanıcıları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetProjectInUserGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetProjectInUserGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User Detayını verir
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetUserDetailByIdGet(userId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetUserDetailByIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Yetki var ise kullanıcı listesini döner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetUserListGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetUserListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Kullanıcıya ait projeleri listeler
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetUserProjectListGet(userId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetUserProjectListGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Kullanıcı rol listesini gösterir
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetUserRoleListGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetUserRoleListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Kullanıcı Tip listesini gösterir
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetUserTypeListGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetUserTypeListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login
         * @param {string} [username] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserLoginGet(username?: string, password?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserLoginGet(username, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [projectID] 
         * @param {number} [userID] 
         * @param {number} [appVersion] 
         * @param {string} [platform] 
         * @param {string} [model] 
         * @param {string} [machine] 
         * @param {string} [notificationToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserSplashControlGet(projectID?: number, userID?: number, appVersion?: number, platform?: string, model?: string, machine?: string, notificationToken?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserSplashControlGet(projectID, userID, appVersion, platform, model, machine, notificationToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  Kullanıcıya yeni şifre atanır  bunu sadece admin veya yönetici kullanabilmeli
         * @param {number} [userId] 
         * @param {string} [newPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUpdateUserPasswordGet(userId?: number, newPassword?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUpdateUserPasswordGet(userId, newPassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  kullanıcı günceller  Şifre zorunlu değildir, gönderirsen günceller, göndermezsen güncellemez
         * @param {UpdateUserDTO} [updateUserDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUpdateUserPost(updateUserDTO?: UpdateUserDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUpdateUserPost(updateUserDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary kullanıcıyı aktif hale getirir
         * @param {number} [userId] 
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUserActiveGet(userId?: number, projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUserActiveGet(userId, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary kullanıcıyı pasif yapar
         * @param {number} [userId] 
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUserPassiveGet(userId?: number, projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUserPassiveGet(userId, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary kullanıcıyı projeye dahil eder
         * @param {number} [userId] 
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAddUserInProjectGet(userId?: number, projectId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserAddUserInProjectGet(userId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary kullanıcı ekler  ProjectID = kullanıcıyı bir projeye atamak için kullanıyoruz , Giriş yaptığın projeyi gönder
         * @param {AddUserDTO} [addUserDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAddUserPost(addUserDTO?: AddUserDTO, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserAddUserPost(addUserDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kullanıcı kendi şifresini değiştirir
         * @param {number} [userId] 
         * @param {string} [oldPassword] 
         * @param {string} [newPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserChanceUserPasswordGet(userId?: number, oldPassword?: string, newPassword?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserChanceUserPasswordGet(userId, oldPassword, newPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary kullanıcıyı projeden çıkartır
         * @param {number} [userId] 
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserDeleteUserInProjectGet(userId?: number, projectId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserDeleteUserInProjectGet(userId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Projede olan kullanıcıları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetProjectInUserGet(projectId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserGetProjectInUserGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User Detayını verir
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUserDetailByIdGet(userId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserGetUserDetailByIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Yetki var ise kullanıcı listesini döner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUserListGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiUserGetUserListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kullanıcıya ait projeleri listeler
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUserProjectListGet(userId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserGetUserProjectListGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kullanıcı rol listesini gösterir
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUserRoleListGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiUserGetUserRoleListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kullanıcı Tip listesini gösterir
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUserTypeListGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiUserGetUserTypeListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login
         * @param {string} [username] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserLoginGet(username?: string, password?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserLoginGet(username, password, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [projectID] 
         * @param {number} [userID] 
         * @param {number} [appVersion] 
         * @param {string} [platform] 
         * @param {string} [model] 
         * @param {string} [machine] 
         * @param {string} [notificationToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSplashControlGet(projectID?: number, userID?: number, appVersion?: number, platform?: string, model?: string, machine?: string, notificationToken?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserSplashControlGet(projectID, userID, appVersion, platform, model, machine, notificationToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  Kullanıcıya yeni şifre atanır  bunu sadece admin veya yönetici kullanabilmeli
         * @param {number} [userId] 
         * @param {string} [newPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUpdateUserPasswordGet(userId?: number, newPassword?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserUpdateUserPasswordGet(userId, newPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  kullanıcı günceller  Şifre zorunlu değildir, gönderirsen günceller, göndermezsen güncellemez
         * @param {UpdateUserDTO} [updateUserDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUpdateUserPost(updateUserDTO?: UpdateUserDTO, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserUpdateUserPost(updateUserDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary kullanıcıyı aktif hale getirir
         * @param {number} [userId] 
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserActiveGet(userId?: number, projectId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserUserActiveGet(userId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary kullanıcıyı pasif yapar
         * @param {number} [userId] 
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserPassiveGet(userId?: number, projectId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserUserPassiveGet(userId, projectId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary kullanıcıyı projeye dahil eder
     * @param {number} [userId] 
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserAddUserInProjectGet(userId?: number, projectId?: number, options?: any) {
        return UserApiFp(this.configuration).apiUserAddUserInProjectGet(userId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary kullanıcı ekler  ProjectID = kullanıcıyı bir projeye atamak için kullanıyoruz , Giriş yaptığın projeyi gönder
     * @param {AddUserDTO} [addUserDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserAddUserPost(addUserDTO?: AddUserDTO, options?: any) {
        return UserApiFp(this.configuration).apiUserAddUserPost(addUserDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kullanıcı kendi şifresini değiştirir
     * @param {number} [userId] 
     * @param {string} [oldPassword] 
     * @param {string} [newPassword] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserChanceUserPasswordGet(userId?: number, oldPassword?: string, newPassword?: string, options?: any) {
        return UserApiFp(this.configuration).apiUserChanceUserPasswordGet(userId, oldPassword, newPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary kullanıcıyı projeden çıkartır
     * @param {number} [userId] 
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserDeleteUserInProjectGet(userId?: number, projectId?: number, options?: any) {
        return UserApiFp(this.configuration).apiUserDeleteUserInProjectGet(userId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Projede olan kullanıcıları listeler
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetProjectInUserGet(projectId?: number, options?: any) {
        return UserApiFp(this.configuration).apiUserGetProjectInUserGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User Detayını verir
     * @param {number} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetUserDetailByIdGet(userId?: number, options?: any) {
        return UserApiFp(this.configuration).apiUserGetUserDetailByIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Yetki var ise kullanıcı listesini döner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetUserListGet(options?: any) {
        return UserApiFp(this.configuration).apiUserGetUserListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kullanıcıya ait projeleri listeler
     * @param {number} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetUserProjectListGet(userId?: number, options?: any) {
        return UserApiFp(this.configuration).apiUserGetUserProjectListGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kullanıcı rol listesini gösterir
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetUserRoleListGet(options?: any) {
        return UserApiFp(this.configuration).apiUserGetUserRoleListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kullanıcı Tip listesini gösterir
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetUserTypeListGet(options?: any) {
        return UserApiFp(this.configuration).apiUserGetUserTypeListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login
     * @param {string} [username] 
     * @param {string} [password] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserLoginGet(username?: string, password?: string, options?: any) {
        return UserApiFp(this.configuration).apiUserLoginGet(username, password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [projectID] 
     * @param {number} [userID] 
     * @param {number} [appVersion] 
     * @param {string} [platform] 
     * @param {string} [model] 
     * @param {string} [machine] 
     * @param {string} [notificationToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserSplashControlGet(projectID?: number, userID?: number, appVersion?: number, platform?: string, model?: string, machine?: string, notificationToken?: string, options?: any) {
        return UserApiFp(this.configuration).apiUserSplashControlGet(projectID, userID, appVersion, platform, model, machine, notificationToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  Kullanıcıya yeni şifre atanır  bunu sadece admin veya yönetici kullanabilmeli
     * @param {number} [userId] 
     * @param {string} [newPassword] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserUpdateUserPasswordGet(userId?: number, newPassword?: string, options?: any) {
        return UserApiFp(this.configuration).apiUserUpdateUserPasswordGet(userId, newPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  kullanıcı günceller  Şifre zorunlu değildir, gönderirsen günceller, göndermezsen güncellemez
     * @param {UpdateUserDTO} [updateUserDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserUpdateUserPost(updateUserDTO?: UpdateUserDTO, options?: any) {
        return UserApiFp(this.configuration).apiUserUpdateUserPost(updateUserDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary kullanıcıyı aktif hale getirir
     * @param {number} [userId] 
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserUserActiveGet(userId?: number, projectId?: number, options?: any) {
        return UserApiFp(this.configuration).apiUserUserActiveGet(userId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary kullanıcıyı pasif yapar
     * @param {number} [userId] 
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserUserPassiveGet(userId?: number, projectId?: number, options?: any) {
        return UserApiFp(this.configuration).apiUserUserPassiveGet(userId, projectId, options).then((request) => request(this.axios, this.basePath));
    }
}


