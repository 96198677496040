import React, { useEffect, useLayoutEffect } from "react";
import {
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Input,
  DrawerFooter,
  Button,
  FormControl,
  FormLabel,
  Select,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import { Hooks } from "services";
import { useRedux } from "hooks/redux-hooks";

interface AddUserProps {
  onClose: () => void;
  projectId: number;
  onSuccess?: () => void;
}

interface Form {
  userRoleID: string;
  userTypeID: string;
  name: string;
  surname: string;
  username: string;
  password: string;
}

function AddUser(props: AddUserProps) {
  const [getRoles, statRoles, dataRoles] = Hooks.UserRoleList();
  const [getTypes, statTypes, dataTypes] = Hooks.UserTypeList();
  const addUserFetch = Hooks.AddUser();
  const [{ auth }] = useRedux();
  const toast = useToast();
  async function onValid(values: Form) {
    try {
      const response = await addUserFetch[0]({
        ...values,
        userRoleID: parseInt(values.userRoleID),
        userTypeID: parseInt(values.userTypeID),
        projectID: auth.user.project.id,
        companyID: auth.user.companyID,
      });
      if ((response as unknown as any).data.success) {
        props.onSuccess && props.onSuccess();
        props.onClose();
      } else {
        toast({
          title: "Hata",
          description: (response as unknown as any).data.message,
          status: "error",
          position: "top-right",
        });
      }
    } catch (error) {}
  }

  useLayoutEffect(() => {
    getRoles();
    getTypes();
  }, []);

  return (
    <DrawerContent>
      <DrawerCloseButton />
      <DrawerHeader>Yeni Kullanıcı Ekle</DrawerHeader>

      <Formik<Form>
        initialValues={{
          name: "",
          surname: "",
          password: "",
          userRoleID: "1",
          userTypeID: "1",
          username: "",
        }}
        onSubmit={onValid}
      >
        <Form>
          <DrawerBody>
            <FormControl mt="3">
              <FormLabel>
                Ad
                <Text color="red" d="inline">
                  *
                </Text>
              </FormLabel>
              <Field as={Input} name="name" />
            </FormControl>
            <FormControl mt="3">
              <FormLabel>
                Soyad
                <Text color="red" d="inline">
                  *
                </Text>
              </FormLabel>
              <Field as={Input} name="surname" />
            </FormControl>
            <FormControl mt="3">
              <FormLabel>
                Kullanıcı Adı
                <Text color="red" d="inline">
                  *
                </Text>
              </FormLabel>
              <Field as={Input} name="username" />
            </FormControl>
            <FormControl mt="3">
              <FormLabel>
                Parola
                <Text color="red" d="inline">
                  *
                </Text>
              </FormLabel>
              <Field as={Input} type="text" name="password" />
            </FormControl>
            <FormControl mt="3">
              <FormLabel>
                Kullanıcı Rolü
                <Text color="red" d="inline">
                  *
                </Text>
              </FormLabel>
              <Field
                as={Select}
                name="userRoleID"
                disabled={statRoles !== "fulfilled"}
              >
                {statRoles === "fulfilled" &&
                  dataRoles?.data.data
                    .filter((item) => auth.user.userRoleID <= item.id)
                    .map((item, index) => (
                      <option key={"role" + index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
              </Field>
            </FormControl>
            <FormControl mt="3">
              <FormLabel>
                Kullanıcı Tipi
                <Text color="red" d="inline">
                  *
                </Text>
              </FormLabel>
              <Field
                as={Select}
                name="userTypeID"
                disabled={statTypes !== "fulfilled"}
              >
                {statRoles === "fulfilled" &&
                  dataTypes?.data.data.map((item, index) => (
                    <option key={"type" + index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Field>
            </FormControl>
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={props.onClose}>
              Vazgeç
            </Button>
            <Button
              colorScheme="blue"
              type="submit"
              isLoading={addUserFetch[1] === "pending"}
            >
              Oluştur
            </Button>
          </DrawerFooter>
        </Form>
      </Formik>
    </DrawerContent>
  );
}

export default AddUser;
