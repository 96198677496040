import MainTemplate from "components/templates/Main";
import React, { useLayoutEffect, useState } from "react";
import {
  Flex,
  Box,
  Text,
  Spinner,
  Drawer,
  DrawerOverlay,
  useDisclosure,
  IconButton,
  Modal,
  ModalBody,
  Button,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import SectionTitle from "components/molecules/SectionTitle";
import { Hooks } from "services";
import { useRedux } from "hooks/redux-hooks";
import Card from "components/atoms/Card";
import { UserListResponse } from "services/model";
import DataTable from "components/atoms/DataTable";
import Moment from "moment";
import StatusBadge from "components/atoms/StatusBadge";
import AddUser from "components/organizms/AddUser";
import EditUser from "components/organizms/EditUser";
import { EditIcon, RepeatIcon } from "@chakra-ui/icons";
import Sure from "components/molecules/Sure";
import { User } from "services/pet";
import { AxiosError } from "axios";

function UserList() {
  const [getUserList, statusUserList, dataUserList] = Hooks.UserList();
  const [updateUser, statusUpdateUser] = Hooks.UpdateUser();
  const [activeUser, statusActive] = Hooks.UserActive();
  const [passiveUser, statusPassiveUser] = Hooks.UserPassive();
  const [{ auth, app }] = useRedux();
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const editDisclosure = useDisclosure();
  const [selectedUser, setUser] = useState<User | null>(null);
  const success = useDisclosure();
  const [stt, setStt] = useState();
  const toast = useToast({ position: "top-right" });

  function _getUser() {
    getUserList(auth.user.project.id);
  }

  async function _toggleUser(user: any) {
    try {
      const response = await (user.isActive
        ? passiveUser(user.id, auth.user.project.id)
        : activeUser(user.id, auth.user.project.id));
      if ((response as unknown as any).data.success) {
        _getUser();
      } else {
        toast({
          title: "Hata",
          description: (response as unknown as any).data.message,
          status: "error",
        });
      }
    } catch (error: any) {
      toast({
        title: "Hata",
        description: error.response.data.message,
        status: "error",
      });
    }
  }

  useLayoutEffect(() => {
    _getUser();
  }, []);

  const userList = dataUserList?.data as unknown as UserListResponse;
  console.log(auth);
  return (
    <MainTemplate>
      <SectionTitle
        title="Kullanıcılar"
        button={{
          colorScheme: "blue",
          onClick: onToggle,
          title: "Yeni Kullanıcı Ekle",
        }}
      />
      <Card>
        {statusUserList !== "fulfilled" ? (
          <Flex justifyContent="center" alignItems="center">
            <Spinner />
          </Flex>
        ) : (
          <DataTable
            columns={[
              {
                name: "Id",
                selector: "id",
                sortable: true,
              },
              {
                name: "Kullanıcı Adı",
                selector: "username",
                sortable: true,
                width: "150px",
              },
              {
                name: "Ad Soyad",
                selector: "fullname",
                sortable: true,
                width: "200px",
              },
              {
                name: "Rol",
                selector: "userRoleName",
                sortable: true,
                width: "200px",
              },
              {
                name: "Tipi",
                selector: "userTypeName",
                sortable: true,
                width: "200px",
              },
              {
                name: "Tanım Tarihi",
                selector: "createDate",
                sortable: true,
                cell: (row: any) => {
                  return Moment(row.createDate).format("DD.MM.YYYY");
                },
              },
              {
                name: "Durum",
                selector: "isActive",
                sortable: true,
                cell: (row: any) => {
                  return <StatusBadge stat={row.isActive} />;
                },
              },
              {
                name: "Aktif / Pasif",
                cell: (row: any, index: number) => {
                  if (auth.user.userRoleID === 3) return null;
                  return (
                    <IconButton
                      size="sm"
                      aria-label="ChangeStatus"
                      icon={<RepeatIcon />}
                      onClick={() => {
                        setStt(row);
                      }}
                    />
                  );
                },
              },
              {
                name: "Düzenle",
                cell: (row: any, index: number) => {
                  if (auth.user.userRoleID === 3) return null;
                  return (
                    <IconButton
                      size="sm"
                      aria-label="ChangeStatus"
                      icon={<EditIcon />}
                      onClick={() => {
                        setUser(row);
                      }}
                    />
                  );
                },
              },
            ]}
            data={userList.data
              .filter((item) =>
                auth.user.userRoleID === 3
                  ? item.id === auth.user.id
                  : auth.user.userRoleID === 2
                  ? item.userRoleID >= 2
                  : true
              )
              .map((item) => ({
                ...item,
                fullname: item.name + " " + item.surname,
              }))}
            excel={[{ label: "Deneme", value: "Deneme" }]}
          />
        )}
      </Card>
      <Sure
        isOpen={!!stt}
        onClose={() => setStt(undefined)}
        onSuccess={() => _toggleUser(stt)}
      />
      <Modal isOpen={success.isOpen} onClose={success.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Başarılı!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>İşlem Başarıyla Gerçekleştirildi</ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={success.onClose}>
              Tamam
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay>
          <AddUser
            {...{
              onClose,
              projectId: parseInt(auth.user.project.id),
              onSuccess: () => {
                success.onToggle();
                _getUser();
              },
            }}
          />
        </DrawerOverlay>
      </Drawer>
      <Drawer
        isOpen={!!selectedUser}
        placement="right"
        onClose={() => {
          setUser(null);
        }}
      >
        <DrawerOverlay>
          <EditUser
            {...{
              onClose: () => {
                setUser(null);
              },
              user: selectedUser!,
              projectId: parseInt(auth.user.project.id),
              onSuccess: () => {
                success.onToggle();
                _getUser();
              },
            }}
          />
        </DrawerOverlay>
      </Drawer>
    </MainTemplate>
  );
}

export default UserList;
