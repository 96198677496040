import React, { useEffect, useLayoutEffect, useState } from "react";
import Card from "components/atoms/Card";
import Auth from "components/templates/Auth";
import {
  Box,
  Select,
  FormControl,
  FormLabel,
  Button,
  Spinner,
  Flex,
} from "@chakra-ui/react";
import { Hooks, ProjectListResponse } from "services";
import { useRedux } from "hooks/redux-hooks";
import { Authenticator } from "utils/authenticator";

function SelectProject() {
  const [getProjectList, status, data] = Hooks.ProjectList();
  const [selectedProject, setSelectedProject] = useState<any>(null);
  const [{ auth }] = useRedux();
  useLayoutEffect(() => {
    getProjectList(auth.user.id);
  }, []);

  useEffect(() => {
    if (status === "fulfilled") {
      const projectList: ProjectListResponse = (data?.data as unknown) as ProjectListResponse;
      if (projectList.data.length === 1) {
        Authenticator.SetProject(auth.token, auth.user, projectList.data[0]);
      }
    }
  }, [status]);

  function onSelect() {
    Authenticator.SetProject(auth.token, auth.user, selectedProject);
  }

  const projectList: ProjectListResponse = (data?.data as unknown) as ProjectListResponse;
  if (!(status === "fulfilled" && projectList.data.length > 1)) {
    return (
      <Auth>
        <Card padding="5" width="100%">
          <Flex justifyContent="center">
            <Spinner />
          </Flex>
        </Card>
      </Auth>
    );
  }
  return (
    <Auth>
      <Card padding="5" width="300px">
        <FormControl>
          <FormLabel>Proje Seçin</FormLabel>
          <Select
            placeholder={
              status !== "fulfilled" ? "Yükleniyor" : "Proje Seçiniz"
            }
            disabled={status !== "fulfilled"}
            onChange={(e) => {
              setSelectedProject(
                projectList.data[parseInt(e.currentTarget.value)]
              );
            }}
          >
            {status === "fulfilled" &&
              projectList.data.map((project, index) => (
                <option key={index} value={index}>
                  {project.name}
                </option>
              ))}
          </Select>
          <Button
            colorScheme="blue"
            mt="3"
            w="100%"
            onClick={onSelect}
            disabled={!selectedProject}
          >
            Seç
          </Button>
        </FormControl>
      </Card>
    </Auth>
  );
}

export default SelectProject;
