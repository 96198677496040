import React, { useEffect } from "react";
import {
  Button,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";
import { Hooks } from "services";

interface AddControlPointProps {
  onClose: () => void;
  projectId: number;
  controlAreaId: number;
  onSuccess?: () => void;
}

interface Form {
  name: string;
}

function AddControlPoint(props: AddControlPointProps) {
  const [postAddCP, statusCP, dataCP] = Hooks.AddControlPoint();
  const toast = useToast();

  function onValid(values: Form) {
    postAddCP({
      controlAreaID: props.controlAreaId,
      projectID: props.projectId,
      name: values.name,
    });
  }

  useEffect(() => {
    const _data = dataCP?.data as unknown as any;
    if (statusCP === "fulfilled" && _data.success) {
      toast({
        title: "Başarılı!",
        description: "Kontrol noktası başarıyla oluşturuldu.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right"
      })
      props.onSuccess && props.onSuccess();
      props.onClose();
    } else if(statusCP === "fulfilled" && !_data.success) {
      toast({
        title: "Hata Oluştu!",
        description: _data.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right"
      })
    }
  }, [statusCP]);

  return (
    <DrawerContent>
      <DrawerCloseButton />
      <DrawerHeader>Kontrol Noktası Oluştur</DrawerHeader>

      <Formik<Form> initialValues={{ name: "" }} onSubmit={onValid}>
        <Form>
          <DrawerBody>
            <FormControl>
              <FormLabel>Kontrol Noktası Adı</FormLabel>
              <Field as={Input} name="name" />
            </FormControl>
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={props.onClose}>
              Vazgeç
            </Button>
            <Button colorScheme="blue" type="submit">
              Oluştur
            </Button>
          </DrawerFooter>
        </Form>
      </Formik>
    </DrawerContent>
  );
}

export default AddControlPoint;
