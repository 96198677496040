import React from "react";
import { Box } from "@chakra-ui/react";
import MainCrud from "components/templates/_Main-Crud";
import { Hooks } from "services";
import AddNotice from "components/organizms/AddNotice";
import { useRedux } from "hooks/redux-hooks";

interface ValuesProps {}

function Notice({}: ValuesProps) {
  const [{ auth }] = useRedux();
  return (
    <MainCrud
      listService={{ hook: Hooks.NoticeList, params: [auth.user.project.id] }}
      title="Duyurular"
      newButtonText="Yeni Duyuru Ekle"
      updateService={{ hook: Hooks.UpdateNotice }}
      addForm={{ Form: AddNotice, props: [] }}
      columns={[
        {
          name: "Başlık",
          selector: "title",
          sortable: true,
        },
        {
          name: "İçerik",
          selector: "title",
          sortable: true,
          width: "250px"
        },
        {
          name: "Tanimlayan",
          selector: "addUserName",
          sortable: true,
        },
      ]}
    />
  );
}

export default Notice;
