import * as C from "./constants";
import { LoginAction, LogoutAction, ProjectAction } from "./type";

export function loginAction(token: any, user: any): LoginAction {
  return {
    type: C.SET_AUTH,
    payload: {
      token,
      user,
    },
  };
}

export function projectAction(project: any): ProjectAction {
  return {
    type: C.SET_PROJECT,
    payload: {
      project,
    },
  };
}

export function logoutAction(): LogoutAction {
  return {
    type: C.CLEAR_AUTH,
  };
}
