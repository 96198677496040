import { Route } from "react-router-dom";
import { mainPaths } from "./path";

export const routes: any[] = [
  {
    route: Route,
    name: "Home",
    component: require("components/pages/Dashboard").default,
    path: mainPaths.Home,
    exact: true,
  },
  {
    route: Route,
    name: "Control Area",
    component: require("components/pages/ControlArea").default,
    path: mainPaths.ControlArea(),
    exact: true,
  },
  {
    route: Route,
    name: "Control Point",
    component: require("components/pages/ControlPoint").default,
    path: mainPaths.ControlPoint(),
    exact: true,
  },
  {
    route: Route,
    name: "Questions",
    component: require("components/pages/Question").default,
    path: mainPaths.Question(),
    exact: true,
  },
  {
    route: Route,
    name: "User",
    component: require("components/pages/User").default,
    path: mainPaths.User,
    exact: true,
  },
  {
    route: Route,
    name: "Shift",
    component: require("components/pages/Shift").default,
    path: mainPaths.Shift,
    exact: true,
  },
  {
    route: Route,
    name: "Mail",
    component: require("components/pages/Mail").default,
    path: mainPaths.Mail,
    exact: true,
  },
  {
    route: Route,
    name: "QuestionType",
    component: require("components/pages/Values").default,
    path: mainPaths.QuestionType,
    exact: true,
  },
  {
    route: Route,
    name: "Notice",
    component: require("components/pages/Notice").default,
    path: mainPaths.Notice,
    exact: true,
  },
  {
    route: Route,
    name: "Settings",
    component: require("components/pages/Settings").default,
    path: mainPaths.Settings,
    exact: true,
  },
  {
    route: Route,
    name: "InfoEdit",
    component: require("components/pages/InfoEdit").default,
    path: mainPaths.InfoEdit,
    exact: true,
  },
  {
    route: Route,
    name: "Reports",
    component: require("components/pages/Report").default,
    path: mainPaths.Report,
    exact: true,
  },
  {
    route: Route,
    name: "List",
    component: require("components/pages/AllList").default,
    path: mainPaths.List,
    exact: true,
  },
  {
    route: Route,
    name: "UsersAndProjects",
    component: require("components/pages/UsersAndProjects").default,
    path: mainPaths.UsersAndProjects,
    exact: true,
  },
];

export const authRoutes: any[] = [
  {
    route: Route,
    name: "Login",
    component: require("components/pages/Login").default,
  },
];
