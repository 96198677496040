import MainTemplate from "components/templates/Main";
import React, { useLayoutEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  Button,
  Text,
  Drawer,
  DrawerOverlay,
  useDisclosure,
  Flex,
  Divider,
  Badge,
  Box,
  IconButton,
} from "@chakra-ui/react";
import Card from "../atoms/Card";
import { Hooks, ProjectListResponse, ControlAreaListResponse } from "services";
import { useRedux } from "hooks/redux-hooks";
import { RouteComponentProps } from "react-router-dom";
import { mainPaths } from "router/path";
import AddControlArea from "components/organizms/AddControlArea";
import { AddIcon, ArrowRightIcon, RepeatIcon } from "@chakra-ui/icons";
import Moment from "moment";
import StatusBadge from "components/atoms/StatusBadge";
import DataTable from "components/atoms/DataTable";
import SectionTitle from "components/molecules/SectionTitle";
import AddUser from "components/organizms/AddUser";
import Sure from "components/molecules/Sure";

type Params = {
  projectId: string;
};

function Project({ match, history }: RouteComponentProps<Params>) {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const [getProjectList, status, data] = Hooks.ProjectList();
  const [getControlAreaList, cAStat, cAData] = Hooks.ControlAreaList();
  const [updateCA, cAUStat, cAUData] = Hooks.UpdateControlArea()
  const [{ auth }] = useRedux();
  const [stt, setStt] = useState<any>();

  async function willMount() {
    getControlAreaList(auth.user.project.id);
  }

  function goControlPoint(controlAreaId: number) {
    history.push(
      mainPaths.ControlPoint(parseInt(auth.user.project.id), controlAreaId)
    );
  }

  async function _updateControlArea(controlArea: any) {
    await updateCA({...controlArea, isActive: !controlArea.isActive});
    getControlAreaList(auth.user.project.id);
  }

  useLayoutEffect(() => {
    willMount();
  }, []);

  const controlAreaList = (cAData?.data as unknown) as ControlAreaListResponse;

  return (
    <MainTemplate>
      <SectionTitle
        title="Kontrol Alanları"
        button={{
          colorScheme: "blue",
          onClick: onToggle,
          title: "Yeni Kontrol Alanı Ekle",
        }}
      />
      <Card>
        {cAStat !== "fulfilled" ? (
          <Flex justifyContent="center" alignItems="center">
            <Spinner />
          </Flex>
        ) : (
          <DataTable
            columns={[
              {
                name: "Id",
                selector: "id",
              },
              {
                name: "Kod",
                selector: "barcode",
                sortable: true,
              },
              {
                name: "Kontrol Bölgesi Adı",
                selector: "name",
                width: "300px",
                sortable: true,
              },
              {
                name: "Tanım Tarihi",
                selector: "createDate",
                cell: (row: any) => {
                  return Moment(row.createDate).format("DD.MM.YYYY");
                },
                sortable: true,
                width: "180px",
              },
              {
                name: "Durum",
                selector: "isActive",
                cell: (row: any) => {
                  return <StatusBadge stat={row.isActive} />;
                },
                sortable: true,
              },
              {
                name: "Kontrol Bölgeleri",
                cell: (controlArea: any) => {
                  return (
                    <Button
                      size="small"
                      p="2"
                      colorScheme="blue"
                      onClick={() => {
                        goControlPoint(controlArea.id);
                      }}
                    >
                      Kontrol Noktaları
                    </Button>
                  );
                },
                width: "200px",
              },
              {
                name: "Aktif / Pasif",
                cell: (row: any, index: number) => {
                  if (auth.user.userRoleID === 3) return null;
                  return (
                    <IconButton
                      size="sm"
                      aria-label="ChangeStatus"
                      icon={<RepeatIcon />}
                      onClick={(e) => {
                        setStt(row);
                      }}
                    />
                  );
                },
              },
            ]}
            data={cAData?.data.data.map((item: any) => ({
              ...item,
            }))}
          />
        )}
      </Card>
      <Sure
        isOpen={stt !== undefined}
        onClose={() => setStt(undefined)}
        onSuccess={() => !!stt && _updateControlArea(stt)}
      />
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay>
          <AddControlArea
            onClose={onClose}
            projectId={auth.user.project.id}
            onSuccess={() => {
              getControlAreaList(parseInt(auth.user.project.id));
            }}
          />
        </DrawerOverlay>
      </Drawer>
    </MainTemplate>
  );
}

export default Project;
