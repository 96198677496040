import React, { useEffect } from "react";
import {
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Input,
  DrawerFooter,
  Button,
  FormControl,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import { Hooks } from "services";
import { useRedux } from "hooks/redux-hooks";

interface AddControlAreaProps {
  onClose: () => void;
  projectId: number;
  onSuccess?: () => void;
}

interface Form {
  barcode: string;
  name: string;
}

function AddControlArea(props: AddControlAreaProps) {
  const [getAddCA, statusCA, dataCA] = Hooks.AddControlArea();
  const [{auth }] = useRedux();
  const toast = useToast();

  async function onValid(values: Form) {
    getAddCA(auth.user.project.id, values.barcode, values.name);
  }

  useEffect(() => {
    const _data = dataCA?.data as unknown as any;
    if(statusCA === "fulfilled" && _data.success) {
      toast({
        title: "Başarılı!",
        description: "Kontrol bölgesi başarıyla oluşturuldu.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right"
      })
      props.onSuccess && props.onSuccess();
      props.onClose();
    } else if (statusCA === "fulfilled" && !_data.success) {
      toast({
        title: "Hata Oluştu!",
        description: _data.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right"
      })
    }
  }, [statusCA]);

  return (
    <DrawerContent>
      <DrawerCloseButton />
      <DrawerHeader>Kontrol Bölgesi Oluştur</DrawerHeader>

      <Formik<Form>
        initialValues={{ barcode: "", name: "" }}
        onSubmit={onValid}
      >
        <Form>
          <DrawerBody>
            <FormControl mt="3">
              <FormLabel>Kontrol Bölgesi Adı</FormLabel>
              <Field as={Input} name="name" />
            </FormControl>
            <FormControl mt="3">
              <FormLabel>Kod</FormLabel>
              <Field as={Input} name="barcode" />
            </FormControl>
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={props.onClose}>
              Vazgeç
            </Button>
            <Button colorScheme="blue" type="submit">
              Oluştur
            </Button>
          </DrawerFooter>
        </Form>
      </Formik>
    </DrawerContent>
  );
}

export default AddControlArea;
