import React, { useEffect, useLayoutEffect } from "react";
import {
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Input,
  DrawerFooter,
  Button,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import { Hooks } from "services";
import { useRedux } from "hooks/redux-hooks";
import InputMask from "components/atoms/InputMask";

interface AddUserProps {
  onClose: () => void;
  projectId: number;
  onSuccess?: () => void;
}

interface Form {
  startHour: string;
  endHour: string;
}

function AddShift(props: AddUserProps) {
  const addItem = Hooks.AddShift();
  const [{ auth }] = useRedux();

  async function onValid(values: Form) {
    addItem[0]({
      ...values,
      projectID: auth.user.project.id,
    });
  }

  useEffect(() => {
    if (addItem[1] === "fulfilled") {
      props.onSuccess && props.onSuccess();
      props.onClose();
    }
  }, [addItem[1]]);

  return (
    <DrawerContent>
      <DrawerCloseButton />
      <DrawerHeader>Yeni Kontrol Saati Ekle</DrawerHeader>

      <Formik<Form>
        initialValues={{
          endHour: "",
          startHour: "",
        }}
        onSubmit={onValid}
      >
        <Form>
          <DrawerBody>
            <FormControl mt="3">
              <FormLabel>Başlangıç Saati</FormLabel>
              <Field as={InputMask} name="startHour" mask="99:99" />
            </FormControl>
            <FormControl mt="3">
              <FormLabel>Bitiş Saati</FormLabel>
              <Field as={InputMask} name="endHour" mask="99:99" />
            </FormControl>
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={props.onClose}>
              Vazgeç
            </Button>
            <Button
              colorScheme="blue"
              type="submit"
              isLoading={addItem[1] === "pending"}
            >
              Oluştur
            </Button>
          </DrawerFooter>
        </Form>
      </Formik>
    </DrawerContent>
  );
}

export default AddShift;
