import React from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorMode,
  Fade,
  ScaleFade,
  Slide,
  SlideFade,
  useDisclosure,
  Divider,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  useMediaQuery,
} from "@chakra-ui/react";
import { FaArrowLeft, FaBars, FaChevronDown } from "react-icons/fa";
import { Authenticator } from "utils/authenticator";
import { useRedux } from "hooks/redux-hooks";
import { useHistory } from "react-router";
import { mainPaths } from "router/path";

function FadeEx({ username }: any) {
  const { isOpen, onToggle } = useDisclosure();
  const history = useHistory();
  return (
    <Popover>
      <PopoverTrigger>
        <Button size="sm" rightIcon={<FaChevronDown />}>{username}</Button>
      </PopoverTrigger>
      <PopoverContent
        overflow="hidden"
        shadow="none"
        outline="none"
        marginRight="5"
      >
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody padding="0" overflow="hidden">
          <Box borderRadius="4">
            <Box
              p="4"
              py="2"
              _hover={{ bg: "blue.50" }}
              onClick={(e) => history.push(mainPaths.InfoEdit)}
            >
              <Text fontSize="small">Bilgileri Güncelle</Text>
            </Box>
            <Divider />
            <Box
              p="4"
              py="2"
              _hover={{ bg: "blue.50" }}
              onClick={(e) => history.push(mainPaths.Settings)}
            >
              <Text fontSize="small">Şifre Değiştir</Text>
            </Box>
            <Divider />
            <Box
              p="4"
              py="2"
              _hover={{ bg: "blue.50" }}
              onClick={() => Authenticator.Logout()}
            >
              <Text fontSize="small">Çıkış Yap</Text>
            </Box>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
function Navbar(props: any) {
  const { toggleColorMode, colorMode } = useColorMode();
  const [isMobile] = useMediaQuery("(max-width: 650px)");
  const [{ auth }] = useRedux();
  const history = useHistory();
  return (
    <Flex
      bgColor="white"
      p="5"
      justifyContent="space-between"
      alignItems="center"
      boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
    >
      {!isMobile && history.length > 2 && (
        <Button
          colorScheme="blue"
          variant="outline"
          size="sm"
          onClick={() => {
            if (history.length > 2) {
              history.goBack();
            }
          }}
          leftIcon={<Icon as={FaArrowLeft} />}
        >
          Geri
        </Button>
      )}
      {isMobile && (
        <Button
          colorScheme="blue"
          variant="outline"
          size="sm"
          onClick={props.sidebarToggle}
        >
          <Icon as={FaBars} />
        </Button>
      )}
      <Text
        color="black"
        fontWeight="extrabold"
        textTransform="uppercase"
      ></Text>

      {/* <Flex alignItems="center">
        {auth.user.name + " " + auth.user.surname}
      </Flex> */}
      <FadeEx username={auth.user.name + " " + auth.user.surname} />
    </Flex>
  );
}

export default Navbar;
