const tokenName = "borsaistanbul";

export function setToken(token: string) {
  localStorage.setItem(tokenName, token);
}

export function setUserAndToken(token: string, user: any) {
  localStorage.setItem(tokenName, JSON.stringify({ token, user }));
}

export function getToken() {
  return localStorage.getItem(tokenName);
}

export function getUserAndToken(): { token: string; user: any } | null {
  const item = localStorage.getItem(tokenName);
  if (item) {
    return JSON.parse(item);
  }
  return null;
}

export function clearToken() {
  return localStorage.removeItem(tokenName);
}
