import { AuthReducer, Action } from "./type";
import { CLEAR_AUTH, SET_AUTH, SET_PROJECT } from "./constants";

const initialValues: AuthReducer = {
  status: "freeze",
};

export function authReducers(
  state = initialValues,
  action: Action
): AuthReducer {
  switch (action.type) {
    case SET_AUTH:
      return {
        ...state,
        status: "notHaveProject",
        token: action.payload.token,
        user: action.payload.user,
      };
    case SET_PROJECT:
      return {
        ...state,
        status: "logged",
        user: {
          ...state.user,
          project: action.payload.project,
        },
      };
    case CLEAR_AUTH:
      return {
        status: "unlogged",
      };
    default:
      return {
        ...state,
      };
  }
}
