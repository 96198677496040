import { Flex } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";

function Auth(props: PropsWithChildren<{}>) {
  return (
    <Flex justifyContent="center" alignItems="center" minH="100vh">
      {props.children}
    </Flex>
  );
}

export default Auth;
