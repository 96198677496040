import {
  Container,
  Box,
  Flex,
  Button,
  SlideFade,
  useDisclosure,
  Center,
  Text,
  useMediaQuery,
  Drawer,
  DrawerOverlay,
} from "@chakra-ui/react";
import Navbar from "components/organizms/Navbar";
import Sidebar from "components/organizms/Sidebar";
import { useRedux } from "hooks/redux-hooks";
import React, { PropsWithChildren, useState } from "react";

function MainTemplate({ children }: PropsWithChildren<{}>) {
  const [largeContainer, setLargeContainer] = useState(true);
  const [{ auth }] = useRedux();
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  const [isMobile] = useMediaQuery("(max-width: 650px)");
  const sidebarDisc = useDisclosure();


  return (
    <Flex alignItems="stretch" minH="100vh">
      {!isMobile && <Sidebar sidebarToggle={sidebarDisc.onToggle} />}
      {isMobile && <Drawer isOpen={sidebarDisc.isOpen} placement="left" onClose={sidebarDisc.onClose}>
        <DrawerOverlay>
          <Sidebar sidebarToggle={sidebarDisc.onToggle} />
        </DrawerOverlay>
      </Drawer>}
      <Flex flexDir="column" w="100%" overflow="hidden" bgColor="background">
        <Navbar sidebarToggle={sidebarDisc.onToggle} />
        <Flex
          flexDir="column"
          overflowY="scroll"
          minH="calc(100vh - 115px)"
          maxH="calc(100vh - 115px)"
          padding="5"
        >
          <Container maxW="1200px" padding={isMobile ? 0 : undefined}>{children}</Container>
        </Flex>
        <Flex
          justifyContent="space-between"
          padding="3"
          backgroundColor="gray.100"
        >
          <Text fontSize="small">{auth?.user?.project?.projectTitle}</Text>
          <Text fontSize="small">{auth.user.project.name}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default MainTemplate;
