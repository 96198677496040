import React from "react";
import { Box, Button, Flex, Text, Icon } from "@chakra-ui/react";
import { useCurrentUser } from "hooks/redux-hooks";
import { FaPlus } from "react-icons/fa";

function SectionTitle(props: {
  title: string;
  button?: { title: string; onClick: () => void; colorScheme: string };
  color?: string;
}) {
  const user = useCurrentUser()
  return (
    <Flex alignItems="center" justifyContent="space-between" mb="5" mt="3">
      <Flex alignItems="center">
        <Box
          w="25px"
          h="25px"
          bgColor={!!props.color ? `${props.color}.500` : "green.500"}
          borderRadius="md"
          mr="2"
        />
        <Text
          fontSize="md"
          color="black"
          fontWeight="bold"
          textTransform="uppercase"
          mt="-2px"
        >
          {props.title}
        </Text>
      </Flex>
      {user.userRoleID !== 3 && props.button && (
        <Button
          size="sm"
          colorScheme={props.button.colorScheme}
          onClick={props.button.onClick}
          leftIcon={<Icon as={FaPlus} />}
        >
          <Text fontSize="small">{props.button.title}</Text>
        </Button>
      )}
    </Flex>
  );
}

export default SectionTitle;
