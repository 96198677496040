import React, { useLayoutEffect, useState } from "react";
import {
  Flex,
  Box,
  Text,
  BoxProps,
  Icon,
  Select,
  SimpleGrid,
} from "@chakra-ui/react";
import Card from "components/atoms/Card";
import { IconType } from "react-icons";
import { FaAirbnb, FaCircle } from "react-icons/fa";
import Chart from "react-apexcharts";
import { colors } from "themes/lightTheme";

interface PieCardProps {
  textColor?: string;
  title?: string;
  icon?: IconType;
  data: any;
  defaultIndex: number;
}

function PieCard({
  textColor,
  title,
  data,
  defaultIndex,
  ...rest
}: PieCardProps & BoxProps) {
  const [selectedIndex, setIndex] = useState<number>(0);
  console.log({ data });
  const [chart, setChart] = useState({
    options: {
      chart: {
        maxWidth: 30,
      },
      colors: [colors.green["400"], colors.red["400"]],
      labels: ["Yanıtlanan", "Yanıtlanmayan"],
      legend: {
        show: false,
      },
    },
  });

  useLayoutEffect(() => {
    setIndex(defaultIndex);
  }, [defaultIndex]);

  return (
    <Card {...rest}>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex justifyContent="center" flexDir="column">
          <Icon as={FaCircle} fontSize="xl" color={"gray.600"} />
          <Text
            fontSize="sm"
            fontWeight="bold"
            color={"gray.600"}
            textTransform="uppercase"
          >
            {title}
          </Text>
        </Flex>
        <Flex justifyContent="flex-end">
          <Select
            size="xs"
            rounded="md"
            color="gray.500"
            value={selectedIndex}
            onChange={(e) => setIndex(parseInt(e.currentTarget.value))}
          >
            {data &&
              data.map((item: any, index: number) => (
                <option value={index} key={index}>
                  {item.shift}
                </option>
              ))}
          </Select>
        </Flex>
      </Flex>
      <Flex mt="2" alignItems="center">
        <Box ml="-10" w="80%">
          {data && (
            <Chart
              options={chart.options}
              series={
                data
                  ? [
                      data[selectedIndex].dailyPositive,
                      data[selectedIndex].dailyNegative,
                    ]
                  : []
              }
              type="pie"
              width="100%"
            />
          )}
        </Box>
        {data && (
          <Flex flexDir="column" justifyContent="center">
            <Flex alignItems="center">
              <Box w="15px" h="15px" bgColor="blue.300" rounded="sm" mr="2" />
              <Flex flexDir="column">
                <Text fontSize="10px" lineHeight="10px">
                  Toplam
                </Text>
                <Text fontWeight="semibold" fontSize="xs">
                  {data[selectedIndex].dailyPositive +
                    data[selectedIndex].dailyNegative}{" "}
                  Ad
                </Text>
              </Flex>
            </Flex>
            <Flex alignItems="center">
              <Box w="15px" h="15px" bgColor="green.300" rounded="sm" mr="2" />
              <Flex flexDir="column">
                <Text fontSize="10px" lineHeight="10px">
                  Yanıtlanan
                </Text>
                <Text fontWeight="semibold" fontSize="xs">
                  {data[selectedIndex].dailyPositive} Ad
                </Text>
              </Flex>
            </Flex>
            <Flex alignItems="center">
              <Box w="15px" h="15px" bgColor="red.300" rounded="sm" mr="2" />
              <Flex flexDir="column">
                <Text fontSize="10px" lineHeight="10px">
                  Yanıtlanmayan
                </Text>
                <Text fontWeight="semibold" fontSize="xs">
                  {data[selectedIndex].dailyNegative} Ad
                </Text>
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Card>
  );
}

export default PieCard;
