import MainTemplate from "components/templates/Main";
import React, { useLayoutEffect, useState } from "react";
import {
  Spinner,
  Button,
  Text,
  Flex,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  IconButton,
} from "@chakra-ui/react";
import Card from "../atoms/Card";
import { Hooks, ControlPointListResponse } from "services";
import { useRedux } from "hooks/redux-hooks";
import { RouteComponentProps } from "react-router-dom";
import AddQuestion, { DAYS } from "components/organizms/AddQuestion";
import { QuestionListResponse } from "services/model";
import StatusBadge from "components/atoms/StatusBadge";
import Moment from "moment";
import SectionTitle from "components/molecules/SectionTitle";
import DataTable from "components/atoms/DataTable";
import { RepeatIcon } from "@chakra-ui/icons";
import Sure from "components/molecules/Sure";

type Params = {
  projectId: string;
  controlAreaId: string;
  controlPointId: string;
};

function Question({ match }: RouteComponentProps<Params>) {
  const [getProjectList, status, data] = Hooks.ProjectList();
  const [getControlAreaList, cAStat, cAData] = Hooks.ControlAreaList();
  const [getControlPointList, cPStat, cPData] = Hooks.ControlPointList();
  const [getQuestionList, questStat, questData] = Hooks.QuestionList();
  const [updateQ] = Hooks.UpdateQuestion()
  const [{ auth }] = useRedux();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [stt, setStt] = useState<any>();

  async function willMount() {
    // await getProjectList(auth.user.id);
    await getControlAreaList(parseInt(match.params.projectId));
    await getControlPointList(parseInt(match.params.controlAreaId));
    getQuestionList(parseInt(match.params.controlPointId));
  }

  async function _updateQuestion(question: any) {
    await updateQ({...question, isActive: !question.isActive});
    getQuestionList(parseInt(match.params.controlPointId));
  }

  useLayoutEffect(() => {
    willMount();
  }, []);

  const controlPointList = (cPData?.data as unknown) as ControlPointListResponse;
  const questionList = (questData?.data as unknown) as QuestionListResponse;

  return (
    <MainTemplate>
      <SectionTitle
        title="Sorular"
        button={{
          colorScheme: "blue",
          onClick: onOpen,
          title: "Yeni Soru Ekle",
        }}
      />
      <Card>
        {questStat !== "fulfilled" ? (
          <Flex justifyContent="center" alignItems="center">
            <Spinner />
          </Flex>
        ) : (
          <DataTable
            columns={[
              {
                name: "Id",
                selector: "id",
                sortable: true,
              },
              {
                name: "Kontrol Bölgesi Kod",
                selector: "controlAreaBarcode",
                width: "150px",
                sortable: true,
              },
              {
                name: "Kontrol Bölgesi",
                selector: "controlAreaName",
                width: "250px",
                sortable: true,
              },
              {
                name: "Kontrol Noktası",
                selector: "controlPointName",
                width: "200px",
                sortable: true,
              },
              {
                name: "Kontrol Sorusu",
                selector: "questionText",
                width: "500px",
                sortable: true,
              },
              {
                name: "Soru Tipi",
                selector: "userTypeName",
                sortable: true,
                width: "200px",

              },
              {
                name: "Değer Tipi",
                selector: "questionTypeName",
                width: "200px",
                sortable: true,
              },
              {
                name: "Min Değer",
                selector: "initialValue",
                sortable: true,
              },
              {
                name: "Max Değer",
                selector: "endValue",
                sortable: true,
              },
              {
                name: "Periyot",
                selector: "questionPeriodName",
                sortable: true,
              },
              {
                name: "Periyot Günü",
                sortable: true,
                cell: (row: any) =>
                  row.questionPeriodID === 2
                    ? DAYS[row.periodicDay - 1]
                    : row.periodicDay,
                    
              },
              {
                name: "Kontrol Saati",
                sortable: true,
                cell: (row: any) =>
                  `${row.projectShiftStarHour || ""} ${
                    row.projectShiftEndHour ? "-" + row.projectShiftEndHour : ""
                  }`,
              },
              {
                name: "Tanım Tarihi",
                sortable: true,
                cell: (row: any) => Moment(row.createDate).format("DD.MM.YYYY"),
                width: "180px",
              },
              {
                name: "Tanımlayan Kişi",
                sortable: true,
                selector: "addUserName",
                width: "150px",
              },
              {
                name: "Durum",
                sortable: true,
                cell: (row: any) => <StatusBadge stat={row.isActive} />,
              },
              {
                name: "Aktif / Pasif",
                cell: (row: any, index: number) => {
                  if (auth.user.userRoleID === 3) return null;
                  return (
                    <IconButton
                      size="sm"
                      aria-label="ChangeStatus"
                      icon={<RepeatIcon />}
                      onClick={(e) => {
                        setStt(row);
                      }}
                    />
                  );
                },
              },
            ]}
            data={questionList?.data || []}
          />
        )}
      </Card>
      <Sure
        isOpen={stt !== undefined}
        onClose={() => setStt(undefined)}
        onSuccess={() => !!stt && _updateQuestion(stt)}
      />
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay>
          <AddQuestion
            onClose={onClose}
            controlAreaId={parseInt(match.params.controlAreaId)}
            controlPointId={parseInt(match.params.controlPointId)}
            projectId={parseInt(match.params.projectId)}
            onSuccess={() => {
              getQuestionList(parseInt(match.params.controlPointId));
            }}
          />
        </DrawerOverlay>
      </Drawer>
    </MainTemplate>
  );
}

export default Question;
