import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Flex,
  Button,
  Text,
  Divider,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
} from "@chakra-ui/react";
import { Form, Field, Formik } from "formik";
import Card from "../atoms/Card";
import { Hooks, LoginResponse } from "services";
import { Authenticator } from "utils/authenticator";

interface LoginForm {
  username: string;
  password: string;
}

const WRONG = "Kullanıcı Adı veya Şifre hatalı!";
function Login() {
  const [login, status] = Hooks.Login();
  const [message, setMessage] = useState("");

  async function onValid(values: LoginForm) {
    const _data: any = await login(values.username, values.password);
    const data: LoginResponse = _data.data;
    if (!!data.data) {
      setMessage("");
      Authenticator.Login(data.data.accessToken, data.data);
    } else {
      setMessage(WRONG);
    }
  }

  useEffect(() => {
    if (status === "rejected") {
      setMessage(WRONG);
    }
  }, [status]);

  return (
    <Flex justifyContent="center" alignItems="center" minH="100vh">
      
      <Card p="10" borderColor="gray.50" borderWidth="1px">
     
        <Text fontSize="3xl" textAlign="center" marginBottom="10">
          Giriş Yap
        </Text>
        {message && (
        <Alert status="error" my="5" w="100%" borderRadius="md">
        <AlertIcon />
        <AlertTitle mr={2}>{message}</AlertTitle>
      </Alert>
      )}
        <Formik<LoginForm>
          initialValues={{ password: "", username: "" }}
          onSubmit={onValid}
        >
          {({ errors, touched }) => (
            <Form>
              <FormControl mb="5">
                <FormLabel>Kullanıcı Adı</FormLabel>
                <Field as={Input} name="username" />
              </FormControl>
              <FormControl>
                <FormLabel>Şifre</FormLabel>
                <Field as={Input} name="password" type="password" />
              </FormControl>
              <Button
                mt="5"
                w="100%"
                colorScheme="blue"
                type="submit"
                isLoading={status === "pending"}
              >
                Giriş Yap
              </Button>
            </Form>
          )}
        </Formik>
      </Card>
    </Flex>
  );
}

export default Login;
