import React, { useEffect, useLayoutEffect } from "react";
import {
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Input,
  DrawerFooter,
  Button,
  FormControl,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import { Hooks, UserTypeListResponse } from "services";
import { useRedux } from "hooks/redux-hooks";
import InputMask from "components/atoms/InputMask";

interface AddUserProps {
  onClose: () => void;
  projectId: number;
  onSuccess?: () => void;
}

interface Form {
  mailAddress: string;
  userTypeId: string;
  subject: string;
}

function AddMail(props: AddUserProps) {
  const [addItem, status] = Hooks.AddMail();
  const [getUserTypes, statusUserTypes, dataUserTypes] = Hooks.UserTypeList();
  const [{ auth }] = useRedux();

  async function onValid(values: Form) {
    addItem(
      auth.user.project.id,
      values.mailAddress,
      parseInt(values.userTypeId),
      values.subject
    );
  }

  useLayoutEffect(() => {
    getUserTypes();
  }, []);

  useEffect(() => {
    if (status === "fulfilled") {
      props.onSuccess && props.onSuccess();
      props.onClose();
    }
  }, [status]);

  const typeUserList = (dataUserTypes?.data as unknown) as UserTypeListResponse;

  return (
    <DrawerContent>
      <DrawerCloseButton />
      <DrawerHeader>Yeni Bildirim Maili Ekle</DrawerHeader>

      <Formik<Form>
        initialValues={{
          mailAddress: "",
          userTypeId: "1",
          subject: ""
        }}
        onSubmit={onValid}
      >
        <Form>
          <DrawerBody>
            <FormControl mt="3">
              <FormLabel>Mail Adresi</FormLabel>
              <Field as={Input} name="mailAddress" required />
            </FormControl>
            <FormControl mt="3">
              <FormLabel>Mail Konusu</FormLabel>
              <Field as={Input} name="subject" required />
            </FormControl>
            <FormControl mt="3">
              <FormLabel>Soru Tipi</FormLabel>
              <Field as={Select} name="userTypeId">
                {statusUserTypes === "fulfilled" &&
                  typeUserList.data.map((_type, index) => (
                    <option value={_type.id}>{_type.name}</option>
                  ))}
              </Field>
            </FormControl>
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={props.onClose}>
              Vazgeç
            </Button>
            <Button
              colorScheme="blue"
              type="submit"
              isLoading={status === "pending"}
            >
              Oluştur
            </Button>
          </DrawerFooter>
        </Form>
      </Formik>
    </DrawerContent>
  );
}

export default AddMail;
