import React, {
  useLayoutEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Flex,
  Box,
  Text,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  IconButton,
  Spinner,
} from "@chakra-ui/react";
import MainTemplate from "./Main";
import SectionTitle from "components/molecules/SectionTitle";
import { RepeatIcon } from "@chakra-ui/icons";
import Card from "components/atoms/Card";
import DataTable from "components/atoms/DataTable";
import StatusBadge from "components/atoms/StatusBadge";
import Sure from "components/molecules/Sure";
import Moment from "moment";
import { useCurrentUser } from "hooks/redux-hooks";

interface MainCrudProps {
  title: string;
  newButtonText: string;
  listService: {
    hook: any;
    params?: any[];
  };
  updateService: {
    hook: any;
    params?: any[];
  };
  columns: any[];
  addForm: {
    Form: any;
    props: any[];
  };
}

const DATE_FORMAT_IN = "DD.MM.YYYY HH:mm";

const DATE_FORMAT_OUT = "DD.MM.YYYY";

const MainCrud = forwardRef<any, MainCrudProps>(
  (
    { title, newButtonText, listService, updateService, columns, addForm },
    ref
  ) => {
    const [getList, statusList, list] = listService.hook();
    const [updateOne, statusOne] = updateService.hook();
    const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
    const [stt, setStt] = useState();
    const user = useCurrentUser();

    function _getList() {
      getList(...(listService.params || []));
    }

    async function _toggle(item: any) {
      if (!!item) {
        await updateOne({ ...item, isActive: !item.isActive });
        _getList();
      }
    }

    useLayoutEffect(() => {
      _getList();
    }, []);

    useImperativeHandle(ref, () => ({
      refresh: () => {
        _getList();
      },
    }));

    return (
      <MainTemplate>
        <SectionTitle
          title={title}
          button={{
            title: newButtonText,
            colorScheme: "blue",
            onClick: onToggle,
          }}
        />
        <Card>
          {statusList !== "fulfilled" ? (
            <Flex justifyContent="center" alignItems="center">
              <Spinner />
            </Flex>
          ) : (
            <DataTable
              columns={[
                {
                  name: "ID",
                  selector: "id",
                  sortable: true,
                },
                ...columns,
                {
                  name: "Tanım Tarihi",
                  sortable: true,
                  selector: "createDate",
                  width: "150px",
                  cell: (row: any) =>
                    Moment(
                      row.createDate,
                      row.createDate.length > 16
                        ? Moment.HTML5_FMT.DATETIME_LOCAL
                        : DATE_FORMAT_IN
                    ).format(DATE_FORMAT_OUT),
                },
                {
                  name: "Durum",
                  sortable: true,
                  selector: "isActive",
                  cell: (row: any) => {
                    return (
                      <StatusBadge
                        stat={row.isActive === undefined || row.isActive}
                      />
                    );
                  },
                },
                ...(!!updateOne && user.userRoleID !== 3
                  ? [
                      {
                        name: "Aktif / Pasif",
                        cell: (row: any) => {
                          return (
                            <IconButton
                              size="sm"
                              aria-label="ChangeStatus"
                              icon={<RepeatIcon />}
                              onClick={() => {
                                setStt(row);
                              }}
                            />
                          );
                        },
                      },
                    ]
                  : []),
              ]}
              data={list.data.data}
              excel={[{ label: "Deneme", value: "Deneme" }]}
            />
          )}
        </Card>
        <Sure
          isOpen={!!stt}
          onClose={() => setStt(undefined)}
          onSuccess={() => _toggle(stt)}
        />
        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerOverlay>
            <addForm.Form
              {...{ onClose, onSuccess: _getList, ...(addForm.props || []) }}
            />
          </DrawerOverlay>
        </Drawer>
      </MainTemplate>
    );
  }
);

export default MainCrud;
