import { store } from "store";
import {
  setUserAndToken,
  getUserAndToken,
  clearToken,
} from "utils/storage-manager";
import { loginAction, projectAction, logoutAction } from "store/auth/actions";

export const Authenticator = {
  Login: (token: string, user: any) => {
    setUserAndToken(token, user);
    store.dispatch(loginAction(token, user));
  },
  SetProject: (token: any, user: any, project: any) => {
    setUserAndToken(token, { ...user, project });
    store.dispatch(projectAction(project));
  },
  Logout: () => {
    clearToken();
    store.dispatch(logoutAction());
  },
};
