import React, { FC } from "react";
import { Box, Button, Center, Flex, HStack, Text } from "@chakra-ui/react";

type UserItemProps = {
  user: IUser;
  index: number;
  onClick: ClickButton;
  highlighted?: boolean;
};

const UserItem: FC<UserItemProps> = (props) => {
  const userShowName = `${props.user.name} ${props.user.surname} @${props.user.username}`;

  return (
    <Flex
      p="4"
      alignItems="center"
      h="full"
      bgColor={
        props.highlighted
          ? "blue.100"
          : props.index % 2 !== 0
          ? "white"
          : "gray.50"
      }
    >
      <HStack w="full" justifyContent="space-between">
        <Text>{userShowName}</Text>
        <Button onClick={props.onClick} disabled={props.highlighted} colorScheme="blue" size="sm">
          Projeler
        </Button>
      </HStack>
    </Flex>
  );
};

export default UserItem;
