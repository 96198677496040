import React, { Fragment, useLayoutEffect, useState } from "react";
import MainTemplate from "components/templates/Main";
import SectionTitle from "components/molecules/SectionTitle";
import { Hooks } from "services";
import { useRedux } from "hooks/redux-hooks";
import { Badge, Button, Flex, Spinner } from "@chakra-ui/react";
import Moment from "moment";
import { FaBan, FaCamera } from "react-icons/fa";
import Card from "components/atoms/Card";
import DataTable from "components/atoms/DataTable";
import Lightbox from "react-image-lightbox";
import StatusBadge from "components/atoms/StatusBadge";

interface AllListProps {}

function AllList({}: AllListProps) {
  const [getList, statList, dataList] = Hooks.AllList();
  const [{ auth }] = useRedux();
  useLayoutEffect(() => {
    getList(auth.user?.project.id);
  }, []);
  return (
    <MainTemplate>
      <SectionTitle title="Kontrol Listesi" />
      {statList !== null && (
        <ReportTable
          loading={statList !== "fulfilled"}
          data={dataList?.data.data}
        />
      )}
    </MainTemplate>
  );
}

export function ReportTable({ loading, data }: any) {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const columns = [
    {
      name: "Id",
      selector: "id",
      width: "80px",
      sortable: true,
    },
    {
      name: "Kod",
      selector: "controlAreaBarcode",
      width: "120px",
      sortable: true,
    },

    {
      name: "Kontrol Bolgesi",
      selector: "controlAreaName",
      width: "300px",
      sortable: true,
    },
    {
      name: "Kontrol Noktasi",
      selector: "controlPointName",
      width: "200px",
      sortable: true,
    },
    {
      name: "Kontrol Sorusu",
      selector: "questionText",
      width: "400px",
      sortable: true,
    },
    {
      name: "Soru Tipi",
      selector: "userTypeName",
      width: "200px",
      sortable: true,
    },
    {
      name: "Deger Tipi",
      selector: "questionTypeName",
      width: "200px",
      sortable: true,
    },
    {
      name: "Baslangic Degeri",
      selector: "initialValue",
      width: "140px",
      sortable: true,
    },
    {
      name: "Bitis Degeri",
      selector: "endValue",
      sortable: true,
    },
    {
      name: "Periyod",
      sortable: true,
      selector: "questionPeriodName",
    },
    {
      name: "Periyod Gunu",
      sortable: true,
      selector: "periodicDay",
    },
    {
      name: "Tanim Tarihi",
      sortable: true,
      selector: "isActive",
      width: "200px",
      cell: (row: any) => Moment(row.createDate).format("DD.MM.YYYY HH:mm"),
    },
    {
      name: "Durum",
      sortable: true,
      selector: "createDate",
      width: "100px",
      cell: (row: any) => <StatusBadge stat={row.isActive} />,
    },
  ];
  return (
    <Fragment>
      <Card mt="1">
        {loading ? (
          <Flex justifyContent="center">
            <Spinner />
          </Flex>
        ) : (
          !!data && (
            <DataTable
              columns={columns}
              data={data}
              excel={columns
                .filter((item) => !!item.selector)
                .map((item) => ({ label: item.name, value: item.selector }))}
            />
          )
        )}
      </Card>
      {!!imageSrc && (
        <Lightbox mainSrc={imageSrc} onCloseRequest={() => setImageSrc(null)} />
      )}
    </Fragment>
  );
}

export default AllList;
