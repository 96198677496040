import React, { FC, useEffect, useLayoutEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  SimpleGrid,
  Skeleton,
  Spinner,
  Text,
} from "@chakra-ui/react";
import FlatList from "components/molecules/FlatList";
import { Hooks } from "services";
import UserItem from "components/organizms/UserItem";
import { useRedux } from "hooks/redux-hooks";
import { Redirect } from "react-router-dom";
import MainTemplate from "components/templates/Main";
import Card from "components/atoms/Card";

type UsersAndProjectsProps = {};

const UsersAndProjects: FC<UsersAndProjectsProps> = (props) => {
  const [{ auth }] = useRedux();
  const [state, setState] = useState({
    selectedUser: null as Nullable<IUser>,
  });
  const [getUsers, statusUsers, dataUsers] = Hooks.AllUsers();
  const [getProjects, statusProjects, dataProjects] = Hooks.AllProjects();
  const [getUserProjects, statusUP, dataUP] = Hooks.ProjectList();
  const [addUserToProject, statusUTP] = Hooks.AddUserToProject();

  useLayoutEffect(() => {
    getUsers();
    getProjects();
  }, []);

  useEffect(() => {
    if (state.selectedUser) {
      getUserProjects(state.selectedUser.id);
    }
  }, [state.selectedUser]);

  useEffect(() => {
    if (statusUTP === "fulfilled") {
      if (state.selectedUser) {
        getUserProjects(state.selectedUser.id);
      }
    }
  }, [statusUTP]);

  const isPageLoading = [statusUsers === "fulfilled"].includes(false);

  const isProjectSelect = (project: IProject) => {
    if (dataProjects?.data && dataUP?.data) {
      return dataUP.data.data.find((i: IProject) => i.id === project.id);
    }
    return false;
  };

  if (!auth.user.username.includes("vk")) {
    return <Redirect to="/" />;
  }

  if (isPageLoading) {
    return <Spinner />;
  }

  return (
    <MainTemplate>
      <Card  minH="calc(100vh - 180px)">
        <SimpleGrid
          spacingX="20"
          columns={2}
          minH="calc(100vh - 320px)"
        >
          <Box h="full">
            <FlatList
              title="Kullanıcılar"
              searchFields={["name", "surname", "username"]}
              data={dataUsers?.data.data || []}
              renderItem={({ item, index }) => (
                <UserItem
                  user={item}
                  index={index}
                  highlighted={state.selectedUser?.id === item.id}
                  onClick={() => {
                    setState((prev) => ({ ...prev, selectedUser: item }));
                  }}
                />
              )}
            />
          </Box>
          {state.selectedUser && (
            <Skeleton
              h="full"
              isLoaded={
                statusProjects === "fulfilled" && statusUP === "fulfilled"
              }
            >
              <Box h="full">
                <FlatList
                  title="Projeler"
                  searchFields={["name"]}
                  data={dataProjects?.data.data || []}
                  renderItem={({ item, index }) => {
                    const hasSelected = isProjectSelect(item);
                    return (
                      <Flex
                        p="4"
                        alignItems="center"
                        h="full"
                        bgColor={index % 2 !== 0 ? "white" : "gray.50"}
                      >
                        <HStack w="full" justifyContent="space-between">
                          <Text>{item.name}</Text>
                          <Button
                            colorScheme={hasSelected ? "red" : "purple"}
                            size="sm"
                            onClick={() => {
                              if (!hasSelected) {
                                addUserToProject(
                                  state.selectedUser?.id,
                                  item.id
                                );
                              }
                            }}
                          >
                            {hasSelected ? "Projeden Çıkar" : "Projeye Ekle"}
                          </Button>
                        </HStack>
                      </Flex>
                    );
                  }}
                />
              </Box>
            </Skeleton>
          )}
        </SimpleGrid>
      </Card>
    </MainTemplate>
  );
};

export default UsersAndProjects;
